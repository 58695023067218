<script>
import axios from "axios";
export default {
  name: "LoginPage",
  data() {
    return {
      valid: false,
      
      window: {
          width: 0,
          height: 0
      },

      snackAlert: {
        snackbar: false,
        snackColor: "",
        snackMessage: "",
        snackBarRight: true,
        snackBarTop: true,  
      },
      
      password: "",
      password_errors: [],
      passwordRules: [v => !!v || "Password is required"],

      emailRules: [
          v => !!v || "E-mail is required",
          v =>
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/.test(v) ||
          "E-mail must be valid"
      ],
      email_errors: [],
      email: "",
      cardLoader: false,  
      e1: false,
    };
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    async submit() {
      this.cardLoader = true;

      if (this.valid) {
        try {
          const response = await axios.post(`/api/login`, {
            email: this.email,
            password: this.password
          });

          if (response.data.validate === "success") {
            this.showSnackbar("Login Successful. Redirecting...", "success");
            localStorage.setItem("userId", response.data.user.id);
            localStorage.setItem("token", response.data.access_token);
            localStorage.setItem("userName", response.data.user.user_name);
            localStorage.setItem("email", response.data.user.email);
            localStorage.setItem("mobileNumbers", response.data.user.mobile_number.length > 0 ?response.data.user.mobile_number[0].mobile_number : null);

            console.log(response.data);

            this.$router.push({ path: '/dashboard' });
          } else {
            this.showSnackbar("Invalid email or password.", "error");
          }
        } catch (error) {
          if (error.response) {
            const errorMsg = error.response.data.message || "Invalid email or password.";
            this.showSnackbar(errorMsg, "error");
          } else {
            this.showSnackbar("An error occurred. Please try again later.", "error");
          }
        }
      } else {
        this.showSnackbar("Please fill in all required fields correctly.", "error");
      }

      this.cardLoader = false;
    },

    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    }

  },
};
</script>

<template>
  <v-app>
    <v-container class="pa-0 ma-0 d-flex fill-height" fluid>
      <v-row class="fill-height" no-gutters>
        <!-- Image Section -->
        <v-col
          class="image-container pa-0 ma-0"
          cols="12"
          lg="5"
          md="12"
          sm="12"
          xl="5"
          xs="12"
        >
          <img
            :src="require('@/WEB/ASSETS/bc_img_1.png')"
            class="custom_web_logo"
            cover
          />
          <img
            :src="require('@/WEB/LOGOS/logo1.png')"
            class="overlay_logo"
          />
        </v-col>

        <!-- Login Form Section -->
        <v-col
          class="d-flex align-center justify-center pa-0 ma-0"
          cols="12"
          lg="7"
          md="12"
          sm="12"
          xl="7"
          xs="12"
        >
          <v-container class="fill-height" color="white" fluid>
            
            <v-row align="center" justify="center">
              <v-col cols="12" md="2" sm="12"></v-col>
              <v-col cols="12" md="8" sm="12">
                <v-card color="white" elevation="0">
                  <v-card-title class="custom_topic_sign_in font-weight-bold"><strong>Log In</strong></v-card-title>

                  <v-card-text class="mt-3">
                    <v-form ref="form" v-model="valid">
                      
                      <v-text-field
                        v-model="email"
                        :error-messages="email_errors"
                        :rules="emailRules"
                        autocomplete="username"
                        class="mb-0 mt-5"
                        label="&nbsp;&nbsp;Email"
                        placeholder="Email Address"
                        prepend-inner-icon="mdi-email-outline"
                        required
                        variant="outlined"
                        @keyup.enter="submit"
                      ></v-text-field>
                     
                      <v-text-field
                        v-model="password"
                        :error-messages="password_errors"
                        :append-inner-icon="
                          e1
                            ? 'mdi-eye'
                            : 'mdi-eye-off'
                        "
                        @click:append-inner="e1 = !e1"
                        :rules="passwordRules"
                        :type="e1 ? 'text' : 'password'"
                        autocomplete="current-password"
                        class='mt-3'
                        label="&nbsp;&nbsp;Password"
                        min="8"
                        placeholder="Password"
                        prepend-inner-icon="mdi-lock-outline"
                        required
                        variant="outlined"
                        @keyup.enter="submit"
                      ></v-text-field>

                    </v-form>
                    <v-row class="black--text">
                      
                      <v-col class="text-right mt-5" cols="12">
                        <p class="mb-0">
                          <v-icon left small>mdi-lock-reset</v-icon>&nbsp;
                          <router-link to="/forgot_password">Forgot password?</router-link>
                        </p>
                      </v-col>

                      <v-col cols="12">
                        <v-btn
                          
                          :loading="cardLoader"
                          block
                          class="white--text mt-5"
                          color="error"
                          size="large"
                          @click="submit"
                        >
                          <v-icon left>mdi-account-arrow-right-outline</v-icon>
                          &nbsp;Continue
                        </v-btn>
                      </v-col>

                    </v-row>

                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="2" sm="12"></v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <!-- Add the snackbar component here -->
    <v-snackbar
      v-model="snackAlert.snackbar"
      :color="snackAlert.snackColor"
      :right="snackAlert.snackBarRight"
      :top="snackAlert.snackBarTop"
      timeout="3000"
    >
      {{ snackAlert.snackMessage }}
    </v-snackbar>

  </v-app>
</template>


<style scoped>
.image-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full height */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Prevent scrolling */
  background-color: #f5f5f5; /* Background color to match provided UI */
}

.custom_web_logo {
  width: 100%;
  height: 100%;
}

.fill-height {
  height: 100vh; /* Full viewport height */
}

.pa-0 {
  padding: 0 !important;
}

.ma-0 {
  margin: 0 !important;
}

@media (max-width: 960px) {
  .image-container {
    display: none; /* Hide the image on smaller screens */
  }
}

.text-right {
  text-align: right;
}

.primary--text {
  color: #1976d2 !important;
}

.overlay_logo {
  position: absolute;
  top: 0px;
  left: 45px;
  width: 220px;
  height: auto;
}
</style>

