<template>
  <v-card class="mt-3 card-size" flat>
    <v-row class="px-3">
      <v-col cols="7" class="mt-3">
        <h3 class="page-title">ADD PRODUCTS</h3>
        <h5 class="light-gray-text">Orders placed across your store</h5>
        <v-card-title>
          
        </v-card-title>
      </v-col>
      <v-col cols="5" class="px-4 mt-5">
        <v-row class="tool-bar-style">
            <v-btn size="x-large" class="red-text btn-border" variant="text" @click="addCategoryPageRoute()">
              <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;ADD CATEGORY
            </v-btn>
            <v-divider vertical :thickness="3" class="divider-vertical"></v-divider>
            <v-btn variant="text" size="x-large" class="gray-text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-card elevation="3" class="px-2 pt-2 mx-1 mb-2 product-details-card">
      <h5 class="card-two-title px-3 mt-2 mb-4">ADD PRODUCTS DETAILS</h5>
      <v-divider></v-divider>

      <v-form ref="form">
        <v-row class="pt-6">
          <v-col cols="6" class="px-6">
            <v-card flat class="card-hight-fixed">
              <v-col cols="11">
              <v-text-field
                class="mb-2"
                label="Product Title"
                variant="outlined"
                placeholder="Type title here"
                v-model="form.productTitle"
                :rules="[v => !!v || 'Product title is required']"
                required
              ></v-text-field>

              <v-textarea
                  class="mb-2"
                  label="Product Description"
                  variant="outlined"
                  placeholder="Write a description here..."
                  v-model="form.productDescription"
              ></v-textarea>
              
              <v-card flat>
                <!-- <h4>Product Images</h4> -->
                <div
                  class="drop-area"
                  @drop.prevent="onDrop"
                  @dragover.prevent="onDragOver"
                  @click="triggerFileInput"
                >
                  <input
                    ref="fileInput"
                    type="file"
                    multiple
                    accept="image/*"
                    class="hidden-file-input"
                    @change="onFileChange"
                  />
                  <div
                    v-for="(image, index) in previewImages"
                    :key="index"
                    class="image-card-container"
                  >
                    <v-card
                      class="image-card"
                    >
                      <v-img
                        :src="image"
                        :alt="'Image ' + (index + 1)"
                        class="image-preview"
                      ></v-img>
                      <v-btn
                        icon
                        size="x-small"
                        @click.stop="removeImage(index)"
                        class="remove-icon"
                      >
                        <v-icon size="small" color="red">mdi-delete</v-icon>
                      </v-btn>
                    </v-card>
                  </div> 
                  <div
                    v-if="previewImages.length === 0"
                    class="placeholder-container"
                  >
                    <v-icon size="36">mdi-cloud-upload</v-icon>
                    <span>Drag your photo here OR click to browse from device</span>
                  </div>
                </div>
              </v-card>
            </v-col>
            </v-card>
            </v-col>
          <v-col cols="6" class="px-6">
            <v-card flat class="card-hight-fixed">
              <v-col cols="11">              
              <v-select
                class="mb-2"
                label="Main Category"
                variant="outlined"
                placeholder="Select main category"
                v-model="form.mainCategory"
                item-title="name"
                item-value="id"
                :items="mainCategories"
                @update:modelValue="fetchSubCategories()"
                required
                :rules="[v => !!v || 'Main category is required']"
              />

              <v-select
                class="mb-2"
                label="Sub Category"
                variant="outlined"
                placeholder="Select sub category"
                v-model="form.subCategory"
                :items="subCategories"
                item-title="sub_category_name"
                item-value="sub_category_name"
                :rules="[v => !!v || 'Sub category is required']"
                required
              />
              <v-text-field
                  class="mb-2"
                  label="Size"
                  variant="outlined"
                  placeholder="Type size here (e.g., 10cm, 5in)"
                  v-model="form.size"
                  :rules="[v => sizeValidation(v) || 'Invalid size format']"
              ></v-text-field>
              <v-text-field
                class="mb-2"
                label="Color"
                variant="outlined"
                @click="dialog = true"
                v-model="form.color"
              ></v-text-field>
              <v-dialog v-model="dialog" persistent max-width="400">
                <v-card class="dialog-card-style px-3">
                  <v-card-title>Select Color</v-card-title>
                  <v-card-text>
                    <v-color-picker
                      v-model="form.color"
                      v-model:mode="mode"
                    ></v-color-picker>
                    <v-select
                      v-model="mode"
                      :items="modes"
                      variant="outlined"
                    ></v-select>
                  </v-card-text>
                  <v-btn variant="flat" block class="red-background-text-btn" @click="dialog = false"><v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;&nbsp;&nbsp;ADD</v-btn>
                </v-card>
              </v-dialog>
              <v-text-field
                  class="mb-2"
                  label="Price"
                  variant="outlined"
                  placeholder="Type price here"
                  v-model="form.price"
                  :rules="[v => !isNaN(Number(v)) || 'Price must be a number']"
              ></v-text-field>
              <v-text-field
                  class="mb-2"
                  label="Discount Rate"
                  variant="outlined"
                  placeholder="Type discount rate here"
                  v-model="form.discountRate"
                  :rules="[v => !isNaN(Number(v)) || 'Discount rate must be a number', v => Number(v) >= 0 || 'Discount rate must be 0 or higher', v => Number(v) < 100 || 'Discount rate must be less than 100']"
              ></v-text-field>
              <v-text-field
                  class="mb-2"
                  label="Available Stock"
                  variant="outlined"
                  placeholder="Type stock here"
                  v-model="form.availableStock"
                  :rules="[v => !isNaN(Number(v)) || 'Available stock must be a number']"
              ></v-text-field>
            </v-col>
            </v-card>

          </v-col>
        </v-row>
        <v-divider class="divider-two-style"></v-divider>
        <v-row class="margin-bottomn-fix pa-3 mb-2">
          <v-spacer></v-spacer>
          <v-btn variant="outlined" class="red-background-text mr-3" @click="addProduct">ADD PRODUCTS</v-btn>
          <v-btn variant="outlined" class="gray-background-text" @click="backPageRoute()">CANCEL</v-btn> 
        </v-row>    
      </v-form>
    </v-card>
    <!-- Snackbar to show notifications -->
    <v-snackbar
      v-model="snackAlert.snackbar"
      :color="snackAlert.snackColor"
      :top="snackAlert.snackBarTop"
      :right="snackAlert.snackBarRight"
      timeout="3000"
    >
      {{ snackAlert.snackMessage }}
    </v-snackbar>

  </v-card>
  </template>
    <script>
  import axios from 'axios';
  // import { ref } from 'vue';

  export default {
    name: 'AddProducts',
    data() {
      return {
        snackAlert: {
          snackbar: false, // Snackbar visibility
          snackColor: "", // Snackbar color
          snackMessage: "", // Snackbar message
          snackBarRight: false, // Snackbar position right
          snackBarTop: true,  // Snackbar position top
        },
          
        mainCategories: [],
        subCategories: [],
        form: {
          productTitle: '',
          productDescription: '',
          main_category_name:'',
          mainCategory: null,
          subCategory: null,
          size: '',
          color: '#ffffff',
          price: '',
          discountRate: '',
          availableStock: '',
          images: []
        },
        previewImages: [],
        dialog: false,
        mode: 'hsla',
        modes: ['hsla', 'rgba', 'hexa'],
      };
    },
   

    methods: {
      sizeValidation(value) {
        const sizePattern = /^\d+(\.\d+)?\s*(cm|centimeter|centimeters|mm|millimeter|millimeters|in|inches|m|meter|meters|ft|feet|other)$/i; // Regex to match size formats
      return sizePattern.test(value.trim());
      },
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      onFileChange(event) {
        const files = event.target.files;
        this.handleFiles(files);
      },
      onDrop(event) {
        const files = event.dataTransfer.files;
        this.handleFiles(files);
      },
      onDragOver(event) {
        event.preventDefault();
      },
      // handleFiles(files) {
      //   const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      //   const fileArray = Array.from(files).filter(file => validImageTypes.includes(file.type));

      //   if (fileArray.length === 0) {
      //     alert('Only image files are allowed!');
      //     return;
      //   }

      //   this.form.images.push(...fileArray);
      //   this.previewImages.push(...fileArray.map(file => URL.createObjectURL(file)));
      // },
      // handleFiles(files) {
      //   const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      //   const fileArray = Array.from(files).filter(file => validImageTypes.includes(file.type));

      //   if (fileArray.length === 0) {
      //     alert('Only image files are allowed!');
      //     return;
      //   }

      //   // Save the images in the expected format
      //   fileArray.forEach(file => {
      //     this.form.images.push({ image: file.name }); // Store only the image filename
      //   });

      //   // Save the file previews for UI display
      //   this.previewImages.push(...fileArray.map(file => URL.createObjectURL(file)));
      // },

      handleFiles(files) {
        const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const fileArray = Array.from(files).filter(file => validImageTypes.includes(file.type));

        if (fileArray.length === 0) {
            alert('Only image files are allowed!');
            return;
        }

        // Ensure each item in the array is a File object
        fileArray.forEach(file => {
            if (!(file instanceof File)) {
                console.error('Not a valid File object:', file);
            }
        });

        this.form.images.push(...fileArray);
        this.previewImages.push(...fileArray.map(file => URL.createObjectURL(file)));
    },


      removeImage(index) {
        this.form.images.splice(index, 1); // Remove the image object (containing filename) from form data
        this.previewImages.splice(index, 1); // Remove the preview image URL from the UI
      },

      
      fetchMainCategories() {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token'); 

        axios.post('/api/mainProduct', {
            userId: userId
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            let sample = response.data.data;
            this.mainCategories = sample.map(item => {
                return {
                    id: item.id,
                    name: item.main_category
                };
            });
            console.log('Main categories:', this.mainCategories);
        })
        .catch(error => {
            console.error('Error fetching main categories:', error);
        });
    },


      fetchSubCategories() {
        // if (!this.form.mainCategory) return;
         
          const token = localStorage.getItem('token');
          const userId = localStorage.getItem('userId');
          const mainCatagoryId = this.form.mainCategory;
          console.log('Main Category ID:', this.form.mainCategory);

          axios.post(`/api/subcatagoryProduct`,
          { userId: userId,
            mainCatagoryId: mainCatagoryId 
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })

          .then(response => {
            console.log('API Response:', response); 
            this.subCategories = response.data.data;
            console.log('Subcategories:', this.subCategories);
            // Find and set the main category name
            const selectedCategory = this.mainCategories.find(cat => cat.id === mainCatagoryId);
            this.form.main_category_name = selectedCategory ? selectedCategory.name : '';
            console.log('Selected Main Category Name:', this.form.main_category_name);
            
          })
          .catch(error => {
              console.error('Error fetching sub categories:', error);
          });
      },
   

      async addProduct() {
        const isValid = this.$refs.form.validate();
        if (!isValid) {
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'error';
          this.snackAlert.snackMessage = 'Please fill out all required fields correctly.';
          return;
        }
        const formData = new FormData();
        formData.append('productTitle', this.form.productTitle);
        formData.append('productDescription', this.form.productDescription);
        // formData.append('mainCatagory', this.form.mainCategory);
        formData.append('mainCatagory', this.form.main_category_name); 
        formData.append('subCatagory', this.form.subCategory); 
        formData.append('size', this.form.size);
        formData.append('color', this.form.color);
        formData.append('price', this.form.price);
        formData.append('discountRate', this.form.discountRate);
        formData.append('availableStaock', this.form.availableStock);
        formData.append('userId', localStorage.getItem('userId'));

        console.log('Images array:', this.form.images);
        // Convert images to JSON string
        // const imagesJson = JSON.stringify(this.form.images);
        // formData.append('images', imagesJson);

        // this.form.images.forEach((image, index) => {
        //   formData.append(`productImage${index}`, image);
        // });

        // Convert the images array (with filenames) to a JSON string
        // const imagesJson = JSON.stringify(this.form.images);
        // formData.append('images', imagesJson);
        
        // Log the images JSON to make sure it's correct before sending
          // const imagesJson = JSON.stringify(this.form.images);
          // console.log('Images JSON:', imagesJson); // This should be a JSON string of an array
          // formData.append('productImage', imagesJson);
          // Convert images to Base64
          // const base64Images = await Promise.all(
          //     this.form.images.map(file => this.convertToBase64(file))
          // );

          // Log the Base64 images array to ensure they are correct before sending
          // console.log('Base64 Images:', base64Images);

          // Convert the array to JSON and append to the form data
          // const imagesJson = JSON.stringify(base64Images.map(image => ({ image })));
          // formData.append('productImage', imagesJson);

       

        try {

          const base64Images = await Promise.all(
            this.form.images.map(file => this.convertToBase64(file))
        );

        // Debug: log the Base64 images array
        console.log('Base64 Images:', base64Images);

        const imagesJson = JSON.stringify(base64Images.map(image => ({ image })));
        formData.append('productImage', imagesJson);

        for (let [key, value] of formData.entries()) {
            console.log(`${key}:`, value);
        }

        const token = localStorage.getItem('token');

          const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/addProduct`, formData, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          });

          if (response.data.success) {
            this.snackAlert.snackbar = true;
            this.snackAlert.snackColor = 'success';
            this.snackAlert.snackMessage = 'Product added successfully';
            this.resetForm();
          } else {
            this.snackAlert.snackbar = true;
            this.snackAlert.snackColor = 'error';
            this.snackAlert.snackMessage = 'Failed to add product';
          }
        } catch (error) {
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'error';
          this.snackAlert.snackMessage = 'Error occurred while adding product';
          console.error('Error adding product:', error);
        }
      },

      // Helper method to convert a file to a Base64 string
      convertToBase64(file) {
        return new Promise((resolve, reject) => {
            if (file instanceof Blob) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            } else {
                reject(new Error('Invalid file type'));
            }
        });
    },



      resetForm() {
        this.form = {
          productTitle: '',
          productDescription: '',
          mainCategory: null,
          subCategory: null,
          size: '',
          color: '',
          price: '',
          discountRate: '',
          availableStock: '',
          images: []
        };
        this.previewImages = [];
      },
      
      addCategoryPageRoute(){
        this.$router.push({ path: '/products-category' });
      },
      backPageRoute(){
        this.$router.back();
      },
      showSnackbar(message, color) {
        this.snackAlert.snackbar = true;
        this.snackAlert.snackMessage = message;
        this.snackAlert.snackColor = color;
      }
      
    },
    created() {
      this.fetchMainCategories();
    },
  };
  </script>
  
  <style scoped>

  .page-title {
    font-weight: bold;
  }

  .light-gray-text{
    color: #a09e9e !important;
  }

  .red-text {
    color: #b00d0d;
  }
  
  .gray-text {
    color: #757575 !important;
  }

  .btn-border{
    border-block-end-color: #9a1313;
  }

  .card-size{
    width: 100%;
    padding: 0;
    margin-bottom: 0;
  }

  .tool-bar-style {
    display: flex;
    justify-content: end;
  }

  .divider-vertical{
    color: #3c3b3b !important;
  }

  .card-two-title{
    color: black;
  }

  .drop-area {
    border: 2px dashed #9a1313;
    padding: 20px;
    margin-top: 0px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
  }

  .hidden-file-input {
    display: none;
  }

  .image-card-container {
    margin: 0 8px 8px 0;
  }

  .image-card {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
  }

  .image-preview {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .remove-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: white;
    border-radius: 50%; /* This will make the background round */
    padding: 4px; /* Adjust as needed to control the icon size */
  }

  .remove-icon .v-icon {
    color: red;
  }

  .placeholder-container {
    width: 100%;
    height: 120px;
    text-align: center;
    color: #9a1313;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .product-details-card{
    height: 100%;
  }

  .card-hight-fixed{
    height: 100%;
    overflow: auto;
    max-height: 63vh;
  }

  .divider-two-style{
    margin-top: -5px;
  }

  .margin-bottomn-fix{
    margin-top: 0px;
  }

  .red-background-text {
    background-color: #f0bcbc;
    color: #BB0000;
  } 

  .red-background-text-btn {
    background-color: #f0bcbc;
    color: #BB0000;
    margin-top: -27px;
    margin-bottom: 10px;
  } 

  .gray-background-text {
    background-color: rgb(202, 202, 202);
    color: black;
  } 

  .dialog-card-style{
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
  }

  </style>
  