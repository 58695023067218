<template >
    <v-card class="main-card" >
      <v-row class="px-6">

        <v-col class="mt-3" cols="6">
          <h3 class="page-title">TOTAL COLLECTIONS</h3>
          <h5 class="light-gray-text">Analyse collection data in a variety of stores.</h5>
          <v-card-title>

          </v-card-title>
        </v-col>

        <v-col class="px-4 mt-5" cols="6">
          <v-row class="tool-bar-style">
            <v-spacer></v-spacer>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>

      </v-row>
      <v-card class="px-2 pt-2 mx-1 "  style=" padding-bottom: 5px" >
        <h5 class="card-h4-title px-3 mt-2 mb-4">COLLECTION ANALYSIS
          <div class="tool-bar-style">
            <v-spacer></v-spacer>
            <v-btn class="bg-red-lighten-4 text-red mr-3" variant="outlined" @click="exportPDF">
              <v-icon class="text-red">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
            </v-btn>

            <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
              <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
            </v-btn>
          </div>
        </h5>

        <v-divider class="pb-6"></v-divider>

      <v-data-table
          :headers="headers"
          :items="filteredRoutes"
          :search="search"
          class="dataTable"
      >
        <template v-slot:[`item.agent.name`]="{ item }">
          <div>
            <div>{{ item.agent.name }}</div>
            <div style="color: red">Reg num: {{ item.agent.registration_num }}</div>
          </div>
        </template>

        <template v-slot:[`item.store`]="{ item }">
          <div class="column-content ">{{ item.store }}</div>
        </template>

        <template v-slot:top>
          <v-row>
            <v-col cols="12" md="4">
              <v-date-input
                  v-model="dates"
                  color="red"
                  label="Date"
                  max-width="368"
                  multiple="range"
                  placeholder="Select Date Range"
                  variant="outlined"
              ></v-date-input>
            </v-col>
            <v-col cols="12" md="8">
                <v-text-field
                    v-model="search"
                    clearable
                    label="Search"
                    placeholder="Search"
                    variant="outlined"
                    width="100%"
                ></v-text-field>
              </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.action`]="{ item }" >
          <v-row>
            <v-col  cols="5">
              <v-btn
                  class="mr-6 bg-blue-lighten-4"
                  color="blue"
                  icon
                  rounded="lg"
                  size="small"
                  variant="outlined"
                  width="80"
                  @click="viewItem(item)"
              >
                <v-icon>mdi-check-circle</v-icon> view
              </v-btn>
            </v-col>
            <v-col class=" mr-0 d-flex justify-end" cols="3">
              <v-btn
                  class="editB bg-green-lighten-4 "
                  color="green"
                  icon
                  rounded
                  size="small"
                  variant="outlined"
                  @click="editItem(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
            <v-col class=" ml-0" cols="4">
              <v-btn
                  class="bg-red-lighten-4"
                  color="red"
                  icon
                  rounded
                  size="small"
                  variant="outlined"
                  @click="confirmDelete(item)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
      </v-card>

      <!-- Delete Confirmation Dialog -->
      <v-dialog v-model="deleteDialog" max-height="500" max-width="500" persistent>
        <v-card class="pa-1">
          <v-row dense style="background-color: #d9d9d9;">
            <v-col cols="12" md="10">
              <v-card-title class="headline">DELETE COLLECTION DATA</v-card-title>
            </v-col>
            <v-col class="d-flex justify-end" cols="12" md="2">
              <v-btn class="mt-2 mr-2" icon size="x-small" @click="deleteDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this collection record ?</v-card-text>
          <v-card-text class="font-weight-medium">
            This collection record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>
            <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteDialog = false">CANCEL</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog v-model="view_Dialog" class="solid-dialog" fullscreen persistent >
        <v-card class="card-size">
          <v-toolbar class="pr-5 mb-5" color="white" dense>
            <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="#b00d0d" dark variant="elevated" @click="closeD">
              <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
            </v-btn>
          </v-toolbar>
          <v-divider class="divider-margin-dv"></v-divider>
          <v-row>
            <Collection_Record/>
          </v-row>
        </v-card>
      </v-dialog >

      <!--  collection recode edit view component call to dialog box-->
      <v-dialog v-model="editDialog" class="solid-dialog" fullscreen persistent >
        <v-card class="card-size">
          <v-toolbar class="pr-5 mb-5" color="white" dense>
            <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="#b00d0d" dark variant="elevated" @click="closeD">
              <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
            </v-btn>
          </v-toolbar>
          <v-divider class="divider-margin-dv"></v-divider>
          <v-row>
            <Collection_Record_Edit/>
          </v-row>
        </v-card>
      </v-dialog >

    </v-card>
</template>
<script>
import axios from 'axios';
import Collection_Record_Edit from "@/components/COLLECTION_MANAGEMENT/Collection_Record_Edit.vue";
import { EventBus } from "@/plugins/event-bus";
import {mapActions} from "vuex";
import Collection_Record from "@/components/COLLECTION_MANAGEMENT/Collection_Record.vue";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';


export default {
  components: {Collection_Record,Collection_Record_Edit },
  data() {
    return {
      menu: false,
      dates: [],
      dateRange: '',
      search: '',
      deleteDialog: false,
      itemToDelete: null,
      view_Dialog: false,
      editDialog: false,
      agentID: null,
      headers: [
        { text: 'Oder Id', value: 'order_id', title: 'ODER ID' },
        { text: 'Store Name', value: 'store_name', title: 'STORE NAME' },
        { text: 'Agent Name', value: 'agent.name', title: 'AGENT NAME' },
        { text: 'Quantity Sold', value: 'sales_amount', title: 'TOTAL COLLECTIONS' },
        { text: 'Actions', value: 'action', sortable: false, title: 'ACTION' },
      ],
      collections: [],
    }
  },
  methods: {
    ...mapActions(['updateSharedDataCollection']),

    fetchCollectionsData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/collections`, { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            console.log("API Response:", response.data);
            this.collections = Array.isArray(response.data.data) ? response.data.data : [];
            console.log("collections after assignment:", this.collections);
          })
          .catch(error => {
            console.error("There was an error fetching the collections data!", error);
            this.collections = [];
          });
    },
    updateDateRange() {
      if (this.dates.length === 2) {
        this.dateRange = `${this.dates[0]} - ${this.dates[1]}`;
      }
    },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    deleteItem() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/collections/delete/${this.itemToDelete.id}`,{ userId },{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.collections = this.collections.filter(sale => sale.id !== this.itemToDelete.id);
            this.deleteDialog = false;
            this.itemToDelete = null;
          })
          .catch(error => {
            console.error("There was an error deleting the sale!", error);
          });
    },
    exportPDF() {
      const doc = new jsPDF();

      // Set the title for the document
      doc.text('Collection Analysis', 14, 10);

      // Prepare the data for the table
      const tableData = this.collections.map((item, index) => [
        index + 1,
        item.order_id,
        item.store_name,
        item.agent.name,
        item.sales_amount
      ]);

      // Define the columns for the table
      const tableColumns = ['#', 'Order ID', 'Store Name', 'Agent Name', 'Total Collections'];

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
      });

      // Save the PDF with a name
      doc.save('collection_analysis.pdf');

    },


    exportExcel() {
      const tableData = this.collections.map((item, index) => ({
        '#': index + 1,
        'Order ID': item.order_id,
        'Store Name': item.store_name,
        'Agent Name': item.agent.name,
        'Total Collections': item.sales_amount
      }));

      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Collections');


      XLSX.writeFile(workbook, 'collection_analysis.xlsx');
    },

    // extra function
    otherXL(){
      // Define headers
      const headers = ['Order ID', 'Store Name', 'Agent Name', 'Total Collections'];

      // Prepare the data
      const data = this.collections.map((item) => [
        item.order_id,
        item.store_name,
        item.agent.name,
        item.sales_amount,
      ]);

      // Combine headers and data
      const worksheetData = [headers, ...data];

      // Create a new worksheet
      const ws = XLSX.utils.aoa_to_sheet(worksheetData);

      // Apply bold font to header row
      const headerRange = XLSX.utils.decode_range("A1:D1");
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
        if (!ws[cellAddress]) continue;
        ws[cellAddress].s = { font: { bold: true } };
      }

      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Collections Data');

      // Write the workbook to a file
      XLSX.writeFile(wb, 'collection_analysis.xlsx');

    },

    viewItem(item) {
      // localStorage.removeItem('viewID');
      // localStorage.setItem('viewID', item.id);
      // EventBus.emit('viewAgentDetails', item);

      this.updateSharedDataCollection(item.id);
      this.view_Dialog = true;

    },
    editItem(item) {
      //  functionality to edit item details
      this.updateSharedDataCollection(item.id);
      this.editDialog = true;
    },
    closeD() {
      // localStorage.removeItem('viewID');
      this.view_Dialog = false;
      this.editDialog = false;
    },
    backPageRoute(){
      this.$router.push('/dashboard');
    },
    collectionsManagementButtonClick() {
      // Logic to execute when the second component button is clicked
      this.view_Dialog = false;
      this.editDialog = true;
    },
    s2(){
      this.editDialog = false;
      this.view_Dialog = false;
    },
    setupEventListeners() {
      EventBus.on('collectionsRecodeManagementButtonClick', this.collectionsManagementButtonClick);
      EventBus.on('collectionsRecodeManagementButtonClick2', this.s2);
    },
    removeEventListeners() {
      EventBus.off('collectionsRecodeManagementButtonClick', this.collectionsManagementButtonClick);
      EventBus.off('collectionsRecodeManagementButtonClick2', this.s2);
    }
  },
  mounted() {
    this.setupEventListeners();
    this.fetchCollectionsData();
    console.log('Headers:', this.headers);
    console.log('collections:', this.collections);
  },
  beforeUnmount() {
    this.removeEventListeners();
  },
  computed: {
    filteredRoutes() {
      return this.collections.filter((sale) => {
        if (!sale) return false;

        console.log(sale);

        // Check if the sale's created_at date is within the selected date range
        const withinDateRange =
            !this.dates.length ||
            (sale.created_at &&
                new Date(sale.created_at) >= new Date(this.dates[0]) &&
                new Date(sale.created_at) <= new Date(this.dates[1]));

        // Check if the sale matches the search query
        const matchesSearch =
            !this.search ||
            (sale.store_name && sale.store_name.toLowerCase().includes(this.search.toLowerCase())) ||
            (sale.agent && sale.agent.name && sale.agent.name.toLowerCase().includes(this.search.toLowerCase())) ||
            (sale.order_id && sale.order_id.toLowerCase().includes(this.search.toLowerCase())) ||
            (sale.order_id && sale.order_id.toString().includes(this.search)) ||
            (sale.sales_amount && sale.sales_amount.toString().includes(this.search));

        // Return true if both conditions are met
        return withinDateRange && matchesSearch;
      });
    }

  },
  watch: {
    dates() {
      this.updateDateRange();
    }
  },
}
</script>

<style scoped >

.main-card{
  width:100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.header{
  display: flex;
  margin-top: 20px;
}

.pdf{
  display: flex;
  justify-content: right;

}
.back-button{
  display: flex;
  justify-content: right;
  margin-right: 50px;
  margin-top: 30px;

}

:deep(.dataTable) th, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  text-align: center;
}

:deep(.dataTable) td, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
}

:deep(.dataTable) {
  border: white;
}

:deep(.dataTable )  tr,  th:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}
:deep(.dataTable )  tr,  td:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}




</style>