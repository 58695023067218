<template> 
    <v-card class="mt-3" flat>
        <v-card-title class="gray-text font-weight-bold">
            DASHBOARD
            <REPORTCARDCOMPONENT/>
            <salesAndCollectionAnalysis/>
            <outletMap/>
        </v-card-title>
    </v-card>
</template>

<script>
import REPORTCARDCOMPONENT from "@/components/MAIN_DASHBOARD/report_card_component.vue";
import salesAndCollectionAnalysis from "@/components/MAIN_DASHBOARD/sales_and_collection_analysis.vue";
import outletMap from "@/components/MAIN_DASHBOARD/outlet_map.vue";
import axios from 'axios';
export default {
    components: {
        REPORTCARDCOMPONENT,
        salesAndCollectionAnalysis,
        outletMap
    },

    data(){
        return{
            // totalSales:"",
            // SalesPresentage:"",
            // totalCollection:"",
            // CollectionPresentage:"",
            // OutletPresantage:"",
            // totalStock:"",
            // StockPresantage:"",
            // profitPresantage:"",
            // totalLeasds:"",
            // leadsPresantage:"",
        };
    },

    mounted() {
        // window.Echo.channel('TracingApp')
        //     .listen('LocationUpdated', (e) => {
        //         console.log('User updated:', e.location);
        //         // Update the dashboard with the new user data
        //         this.updateUser(e);
        //     });
    },

    created(){
        this.getDashboardDetails();
    },

    methods: {
        // ...mapActions(['updateSharedDataRoutes']),


        // updateUser(user) {
        //     console.log('fk',user.location);
        // },

        getDashboardDetails() {

        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem("token");

        const formData = new FormData();
        formData.append("userId", userId);

        axios.post('/api/getCount',formData,{
        headers: {
                'Authorization': `Bearer ${token}`
            }

        })
        .then(response => {
            const dashboardData = {
                
            notVisitedCount : response.data.notVisitedCount,
            visitedCount : response.data.visitedCount,

            totalOfficer : response.data.totalOfficer,
            
            }

            console.log('adad',dashboardData);
            this.$store.dispatch('updateDashboardDetails', dashboardData);
        })
        .catch(error => {
            console.error('Error fetching categories:', error);
        });
        },
    }
}
</script>

<style>
.gray-text{
    color: #757575 !important;
}
</style>