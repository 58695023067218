// event-bus.js
import { reactive } from 'vue';

export const EventBus = reactive({
    events: {},
    emit(event, data = null) { // Default to null if data is not provided
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].forEach(callback => callback(data));
    },
    on(event, callback) {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].push(callback);
    },
    off(event, callback) {
        if (!this.events[event]) return;
        this.events[event] = this.events[event].filter(cb => cb !== callback);
    }
});
