<!-- Reset Password Component -->
<template>
    <v-app>
      <v-container fluid class="pa-0 ma-0 d-flex fill-height">
        <v-row no-gutters class="fill-height">
          <!-- Image Section -->
          <v-col
            cols="12"
            xl="5"
            lg="5"
            md="12"
            sm="12"
            xs="12"
            class="image-container pa-0 ma-0"
          >
            <img
              class="custom_web_logo"
              :src="require('@/assets/ASSETS/forgot_password_2.png')"
              cover
            />
            <img
              class="overlay_logo"
              :src="require('@/assets/LOGOS/logo_main.png')"
            />
          </v-col>
  
          <!-- Forgot Password Form Section -->
          <v-col
            cols="12"
            xl="7"
            lg="7"
            md="12"
            sm="12"
            xs="12"
            class="d-flex align-center justify-center pa-0 ma-0"
          >
            <v-container color="white" class="fill-height" fluid>
              <v-row align="center" justify="center">
                <v-col cols="12" sm="12" md="2"></v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-card color="white" elevation="0">
                    <v-card-title class="font-weight-bold">
                      Change Your Password?
                    </v-card-title>

                    <v-card-subtitle class="mt-5">
                        <h4><v-icon left small>mdi-lock-open-outline</v-icon>&nbsp;
                        Please provide your new password details according to the system rules.</h4>
                    </v-card-subtitle>
  
                    <v-card-text class="mt-2">
                      <v-form v-model="valid" ref="form">
                        
                        <v-text-field
                          v-model="password"
                          :rules="passwordRules"
                          label="New Password"
                          prepend-inner-icon="mdi-lock-outline"
                          placeholder="New Password"
                          variant="outlined"
                          type="password"
                          required
                          outlined
                          dense
                          class="mt-2"
                        ></v-text-field>
  
                        <v-text-field
                          v-model="confirmPassword"
                          :rules="confirmPasswordRules"
                          label="Re-Enter"
                          prepend-inner-icon="mdi-lock-outline"
                          placeholder="Re-Enter New Password"
                          variant="outlined"
                          type="password"
                          required
                          outlined
                          dense
                          class="mt-3 mb-3"
                        ></v-text-field>
  
                        <v-divider></v-divider>
  
                        <v-row class="mt-5">
                          <v-col cols="6" >
                            <v-btn
                              @click="forgotPasswordVerifyModule"
                              size="large"
                              block
                              color="error"
                              class="white--text"
                            >
                            <v-icon left>mdi-account-arrow-right-outline</v-icon>
                            &nbsp;Reset Password
                            </v-btn>
                          </v-col>
                          <v-col cols="6">
                            <v-btn
                              @click="backToLogIn"
                              size="large"
                              block
                              variant="tonal"
                            >
                            <v-icon left small>mdi-keyboard-backspace</v-icon>&nbsp;
                            &nbsp;Back to Login
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="2"></v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-dialog v-model="dialog_components" max-width="450px">
          <v-card class="text-left" >
              <v-container color="white" class="align-left justify-left">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-card color="white" flat>
                      <v-card-title class="font-weight-bold">
                          Password Reset Successfully!
                      </v-card-title>
                         
                      <v-row class="black--text">
                          <v-col cols="12">
                          <v-btn
                              @click="backToLogIn"
                              size="large"
                              block
                              color="error"
                              class="white--text mt-5"
                          >
                          <v-icon left small>mdi-keyboard-backspace</v-icon>
                          &nbsp;Back to Login
                          </v-btn>
                          </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
          </v-card>
        </v-dialog>
      </v-container>
      <v-snackbar
        v-model="snackAlert.snackbar"
        :color="snackAlert.snackColor"
        :top="snackAlert.snackBarTop"
        :right="snackAlert.snackBarRight"
        timeout="3000"
      >
        {{ snackAlert.snackMessage }}
        
      </v-snackbar>
    </v-app>
  </template>
  
  <script>
  
  export default {
    name: 'ResetPassword',
    data() {
      return {
        valid: false,        
        dialog_components: false,
        password: '',
        confirmPassword: '',
        passwordRules: [
          value => !!value || 'Please enter your new password',
          value => value.length >= 6 || 'Password must be at least 6 characters',
        ],
        confirmPasswordRules: [
          value => !!value || 'Please confirm your password',
          value => value === this.password || 'Passwords do not match',
        ],
        snackAlert: {
          snackbar: false,
          snackColor: "",
          snackMessage: "",
          snackBarRight: false,
          snackBarTop: true,  
        },
      };
    },
    methods: {
      backToLogIn() {
        this.$router.push('/');
      },
      forgotPasswordVerifyModule() {
        this.dialog_components=true;
      },
            
    },
  };
  </script>
  
  <style scoped>
    .image-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Full height */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Prevent scrolling */
    background-color: #f5f5f5; /* Background color to match provided UI */
    }

    .custom_web_logo {
    width: 100%;
    height: 100%;
    }
  
  .fill-height {
    height: 100vh; /* Full viewport height */
  }
  
  .pa-0 {
    padding: 0 !important;
  }
  
  .ma-0 {
    margin: 0 !important;
  }
  
  @media (max-width: 960px) {
    .image-container {
        display: none; /* Hide the image on smaller screens */
    }
  }
  .overlay_logo {
    position: absolute;
    top: 45px;
    left: 45px;
    width: 220px;
    height: auto;
  }
  </style>
  