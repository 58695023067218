<template >
  <v-container class="fill-height" fluid>
    <v-card class="main-card" height="100%" style="display: flex; flex-direction: column; justify-content: space-between" width="100%">
      <v-card-title>
        <div>
          <v-row>
            <v-col>
              <span class="header text-h5 font-weight-bold ">SETUP FUNCTIONALITIES</span>
              <v-spacer></v-spacer>
              <span class="text-sm-h9 font-weight-light mt-4 text-grey">select witch function should be enabled according to the organization being processed.</span>
            </v-col>
            <v-col class="d-flex justify-end">
              <div class="back-button mt-5">
                <v-btn font-weight-light mt-4 text-grey text-sm-h9 >
                  <v-icon color="black" size="small">mdi-arrow-left-circle</v-icon> BACK
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row class="mt-6 ml-5 " style="height: 10vh">
          <v-card height="80%" width="95%">
          <v-card-text class="text-h6 font-weight-medium ml-5 text-grey-darken-2 ">SETUP FUNCTIONALITIES</v-card-text>
          </v-card>
        </v-row>
      </v-card-title>

      <v-row style="height: 50vh">
        <v-col class="d-flex justify-center " cols="12" md="6">
          <v-card class="text-center" style="padding: 8px; height: 60vh " >
            <v-row v-for="(item, index) in items" :key="item.text" class="align-center no-gutters" style="height: 6vh">
              <v-col class="d-flex align-center " cols="auto">
                <v-switch
                    v-model="item.isOn"
                    :color="item.isOn ? 'red' : 'grey'"
                    class="ma-1"
                    @change="handleSwitchChange(item)"
                ></v-switch>
              </v-col>
              <v-col class="d-flex mb-6 " cols="auto">
                <v-card-text :class="{ 'red-text': item.isOn }" class="ma-1">{{ item.text }}</v-card-text>
              </v-col>
              <v-divider v-if="index < items.length - 1" ></v-divider>
            </v-row>
          </v-card>
        </v-col>


        <v-col class="d-flex justify-center" cols="12" md="6">
          <v-img
              :src="require('@/assets/Organization Management/Dropdown menu-rafiki.png')"
              alt="Description of the image"
              class="my-image"
              style="height: 50%; width: 50%; margin-top: 20px"

          ></v-img>
        </v-col>
      </v-row>

      <!-- footer -->
      <v-card-actions class="justify-end">
      <v-row style=" justify-content:right; padding-right: 70px; ">
        <v-divider style="width: 100vw; margin-top: 20px;"></v-divider>
        <v-btn style="width: 15%;  color: red; box-shadow: none; border-radius: 5px; border: 1px solid red; background-color: rgb(233, 196, 204); margin: 10px; " @click="complete">
          COMPLETE
        </v-btn>
        <v-btn style="width: 15%; color: gray; box-shadow: none; border-radius: 5px; border: 1px solid gray; background-color: lightgrey; margin: 10px; ">
          CANCEL
        </v-btn>
      </v-row>
      </v-card-actions>

    </v-card>
  </v-container>
</template>


<script>
import { mapActions } from "vuex";

export default {
  name: "OrganizationManagement",

  data() {
    return {
      items: [
        { text: 'Dashboard', isOn: true },
        { text: 'Product Management', isOn: false },
        { text: 'Leads Management', isOn: false },
        { text: 'User Management', isOn: false },
        { text: 'Sales Management', isOn: false },
        { text: 'Collection Management', isOn: false },
        { text: 'Tracking Management', isOn: false },
        { text: 'Route Management', isOn: false },
        { text: 'Outlet Management', isOn: false }
      ]
    };
  },
  methods: {
    ...mapActions(['updateSharedDataOrganization']),

    handleSwitchChange(item) {
      const value = item.isOn ? '1' : '0';
      this.updateSharedDataOrganization({ [item.text]: value });
    },

    complete() {
      this.items.forEach(item => {
        this.handleSwitchChange(item);
      });
    },
  },
  created() {
    const savedSettings = localStorage.getItem('organizationSettings');
    if (savedSettings) {
      this.updateSharedDataOrganization(JSON.parse(savedSettings));
    }
  },
};
</script>


    
<style scoped>
    .v-card-title {
      font-weight: bold;
    }

    .red-text {
      color: red;
    }

</style>
