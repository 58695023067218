<template>
  <v-card class="mt-3 card-size" flat>
    <v-row class="pl-2 pr-6">
      <v-col cols="6" class="mt-3">
        <h3 class="page-title">CREATE USER ROLES</h3>
        <h5 class="light-gray-text">These created user roles help you grant permissions for functions.</h5>
        <v-card-title>

        </v-card-title>
      </v-col>
      <v-col cols="6" class="px-4 mt-5">
        <v-row class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-divider vertical :thickness="3" class="divider-vertical"></v-divider>
          <v-btn variant="text" size="x-large" class="gray-text" @click="backPageRoute()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-card elevation="3" class="px-2 pt-2 mx-1 mb-2 leads-details-card">
      <h5 class="card-two-title px-3 mt-2 mb-4" style="color:#666666 ">CREATE NEW USER ROLES</h5>
      <v-divider></v-divider>
      <v-row class="row-width-full">
        <v-col cols="6">
          <v-row dense class="rowClr mx-3 mt-6 mb-3">
            <v-col cols="1">
              <v-card-text><v-icon size="x-large" color="orange">mdi-alert</v-icon></v-card-text>
            </v-col>
            <v-col cols="11">
              <v-card-text>These user roles help you grant permissions and assign related functions to users in the system.</v-card-text>
            </v-col>
          </v-row>

          <v-row class="mb-3 mt-6 mx-0">
            <v-col cols="10">
              <v-form ref="roleForm" v-model="valid">
                <v-select
                    v-model="selectedItem.user_role_id"
                    :items="userTypes"
                    label="Role type *"
                    variant="outlined"
                    placeholder="Select user role type"
                    item-title="name"
                    item-value="id"
                    :rules="roleTypeRules"
                    required
                ></v-select>
              </v-form>
            </v-col>
            <v-col cols="2">
              <v-btn density="default" class="red-text-btn" variant="outlined" icon="mdi-plus-thick" @click="userRoleDialog=true"></v-btn>
            </v-col>
          </v-row>

          <v-row class="mb-3 mt-3 mx-3">
            <v-textarea
                class="mb-2"
                label="Description *"
                v-model="selectedItem.user_role_description"
                variant="outlined"
                placeholder="Type user description here"
                :rules="descriptionRules"
                required
                rows="2"
            ></v-textarea>
          </v-row>
        </v-col>
        <v-divider vertical :thickness="3" class="divider-vertical my-6"></v-divider>
        <v-col cols="5">

          <h5 class="card-two-title px-3 mt-6 mb-4">Setup Permissions</h5>
          <v-card class="pt-2 mb-3 mx-1 mt-1 card-hight-fixed">
            <v-row v-for="(item, index) in permissionsItems" :key="item.id" class="align-center no-gutters">
              <v-row class="ml-3">
                <v-col cols="auto" class="d-flex margin-up-down-two">
                  <v-switch
                      v-model="item.isOn"
                      :color="item.isOn ? 'red' : 'grey'"
                      class="mr-1"
                      @change="handleSwitchChange(item)"
                  ></v-switch>
                </v-col>
                <v-col cols="auto" class="d-flex">
                  <v-card-text :class="{ 'red-text': item.isOn }" class="ml-3 mr-1">{{ item.premission_dashboard }}</v-card-text>
                </v-col>
              </v-row>
              <v-col cols="12" v-if="item.isOn" class="pl-10">
                <v-row class="margin-up-down">
                  <v-col cols="auto" class="d-flex">
                    <v-switch
                        v-model="item.permissions.read"
                        color="red"
                        class="mr-1"
                        @change="handlePermissionChange(item, 'read')"
                    ></v-switch>
                    <v-card-text class="mx-1">Read</v-card-text>
                  </v-col>
                  <v-col cols="auto" class="d-flex">
                    <v-switch
                        v-model="item.permissions.write"
                        color="red"
                        class="mx-1"
                        @change="handlePermissionChange(item, 'write')"
                    ></v-switch>
                    <v-card-text class="mr-1">Write</v-card-text>
                  </v-col>
                  <v-col cols="auto" class="d-flex">
                    <v-switch
                        v-model="item.permissions.delete"
                        color="red"
                        class="mx-1"
                        @change="handlePermissionChange(item, 'delete')"
                    ></v-switch>
                    <v-card-text class="mr-1">Delete</v-card-text>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider v-if="index < permissionsItems.length - 1"></v-divider>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

    </v-card>

    <v-divider class="divider-two-style"></v-divider>
    <v-row class="margin-bottomn-fix pa-3 mb-2">
      <v-spacer></v-spacer>
      <v-btn variant="outlined" class="red-background-text mr-3" @click="createNewRole">CREATE NEW ROLE</v-btn>
      <v-btn variant="outlined" class="gray-background-text" @click="backPageRoute()">CANCEL</v-btn>
    </v-row>

    <!-- Create user role dialog -->
    <v-dialog v-model="userRoleDialog" max-width="500" max-height="500" persistent>
      <v-card class="pa-1">
        <v-row dense class="rowClr ma-1">
          <v-col cols="12" md="9">
            <v-card-title class="headline">CREATE USER ROLE</v-card-title>
          </v-col>
          <v-col cols="12" md="3" class="d-flex justify-end pt-3 pr-3">
            <v-btn icon size="x-small" @click="userRoleDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-divider class="mt-3"></v-divider>

        <v-card-text>Create a new user role type as you prefer.</v-card-text>
        <v-card-text class="textspace">
          <v-text-field
              label="Role Type *"
              variant="outlined"
              placeholder="|Type user role type here."
              v-model="userRoleType"
              required
          ></v-text-field>
        </v-card-text>
        <v-divider class="mb-3 textspace"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="createRole()" variant="outlined" class="bg-red-lighten-4">CREATE</v-btn>&nbsp; &nbsp;
          <v-btn @click="userRoleDialog = false" variant="outlined" class="bg-grey-lighten-2">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Snackbar to show notifications -->
    <v-snackbar
        v-model="snackAlert.snackbar"
        :color="snackAlert.snackColor"
        :top="snackAlert.snackBarTop"
        :right="snackAlert.snackBarRight"
        timeout="3000"
    >
      {{ snackAlert.snackMessage }}

    </v-snackbar>
  </v-card>
</template>

<script>
import axios from 'axios';
export default {
  name: 'CreateUserRole',
  data() {
    return {
      permissionsItems: [],
      userTypes: [],

      userRoleDialog: false,

      userRoleType: '', // This will hold the role type from the dialog
      userRoleDescription: '', // This will hold the description (for now, this will be an empty string)

      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },

      selectedItem: {
        user_role_id: null,
        user_role_description: '',
      },

      roleTypeRules: [
        v => !!v || 'Role type is required',
      ],
      descriptionRules: [
        v => !!v || 'Description is required',
      ],
    };
  },
  mounted() {
    this.getAllUserRoles();
    this.getDashboardPermissions();
  },
  methods: {
    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },
    getDashboardPermissions() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`/api/getDashboard?userId=${userId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the Authorization header with the Bearer token
            }
          })
          .then(response => {
            const dashboards = response.data.data;
            this.permissionsItems = dashboards.map(dashboard => ({
              id: dashboard.id,
              premission_dashboard: dashboard.premission_dashboard,
              isOn: false,
              permissions: { read: false, write: false, delete: false },
            }));
            console.log('Dashboard permissions:', this.permissionsItems);
          })
          .catch(error => {
            console.error('Error fetching dashboard permissions:', error);
          });
    },
    handleSwitchChange(item) {
      if (!item.isOn) {
        // Reset the permissions when the switch is turned off
        item.permissions.read = false;
        item.permissions.write = false;
        item.permissions.delete = false;
      }
    },
    handlePermissionChange(item) {

      if (!item.permissions.read && !item.permissions.write && !item.permissions.delete ) {
        item.permissions.delete = false;
      }
    },

    // createNewRole() {
    //   const userId = parseInt(localStorage.getItem('userId')); // Ensure userId is sent as a number
    //   const token = localStorage.getItem('token');

    //   // Map the permissionsItems to the required format
    //   const dashboards = this.permissionsItems
    //     .filter(item => item.isOn) // Only include dashboards where the switch is ON
    //     .map(item => ({
    //       dashboardId: item.id, // Assuming item.id represents the dashboard ID
    //       readPremission: item.permissions.read,
    //       writePremission: item.permissions.write,
    //       deletePremission: item.permissions.delete,
    //     }));

    //   // Construct the payload in the format expected by the backend
    //   const payload = {
    //     userId, // userId should be a number
    //     userRoleId: parseInt(this.selectedItem.user_role_id), // Ensure userRoleId is sent as a number
    //     description: this.selectedItem.user_role_description, // Map to description
    //     accessDashboard: dashboards, // Use the transformed dashboards array
    //   };

    //   console.log('Payload to be sent:', JSON.stringify(payload, null, 2));

    //   // const jsonPayload = JSON.stringify(payload);
    //   const jsonPayload = JSON.stringify(payload, null, 2);
    //   console.log('Payload :', JSON.stringify(jsonPayload));

    //   axios.post('/api/createNewUserPrermission', jsonPayload, {
    //     headers: {
    //       'Authorization': `Bearer ${token}`,
    //       'Content-Type': 'application/json' // Ensure content type is set to JSON
    //     },
    //   })
    //   .then(response => {
    //     console.log('User role created:', response.data);
    //     // Handle successful creation (e.g., display a success message, reset form)
    //     this.showSnackbar("User role permission created successfully!", "success");
    //   })
    //   .catch(error => {
    //     console.error('Error creating user role permisiion:', error);
    //     // Handle error (e.g., display an error message)
    //   });
    // },

    createNewRole() {
      const userId = parseInt(localStorage.getItem('userId'), 10);
      const token = localStorage.getItem('token');

      if (!userId || !token) {
        this.snackAlert.snackbar = true;
        this.snackAlert.snackMessage = 'User authentication failed. Please log in again.';
        this.snackAlert.snackColor = 'red';
        return;
      }


      if (!this.$refs.roleForm.validate()) {
        this.snackAlert.snackbar = true;
        this.snackAlert.snackMessage = 'Please fill in all required fields.';
        this.snackAlert.snackColor = 'red';
        return;
      }
      if (!this.selectedItem.user_role_id || !this.selectedItem.user_role_description) {
        this.snackAlert.snackbar = true;
        this.snackAlert.snackMessage = 'Role type and description are required.';
        this.snackAlert.snackColor = 'red';
        return;
      }


      const dashboards = this.permissionsItems
          .filter(item => item.isOn)
          .map(item => ({
            dashboardId: item.id,
            readPremission: item.permissions.read,
            writePremission: item.permissions.write,
            deletePremission: item.permissions.delete,
          }));

      // Build the payload object
      const payload = {
        userRoleId: parseInt(this.selectedItem.user_role_id, 10),
        description: this.selectedItem.user_role_description,
        accessDashboard: dashboards,
        userId,
      };

      console.log('Payload to be sent:', JSON.stringify(payload, null, 2));

        axios.post('/api/createNewUserPrermission', payload, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
            .then(response => {
              console.log('User role created:', response.data);

              this.snackAlert.snackbar = true;
              this.snackAlert.snackMessage = 'User role created successfully!';
              this.snackAlert.snackColor = 'green';

              this.$refs.roleForm.reset();
              this.selectedItem.user_role_id = null;
              this.selectedItem.user_role_description = '';
              this.permissionsItems.forEach(item => {
                item.isOn = false;
                item.permissions.read = false;
                item.permissions.write = false;
                item.permissions.delete = false;
              });

              this.backPageRoute();
            })
            .catch(error => {
              console.error('Error creating user role:', error);

              this.snackAlert.snackbar = true;
              this.snackAlert.snackMessage = 'Failed to create user role. Please try again.';
              this.snackAlert.snackColor = 'red';
            });
    },



    getAllUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/getUserRolesType', { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            let usrRole = response.data.data;
            this.userTypes = usrRole.map(item => ({
              id: item.id,
              name: item.role_type
            }));
            console.log('User role types:', this.userTypes);
          })
          .catch(error => {
            console.error('Error fetching user roles:', error);
          });
    },
    backPageRoute(){
      this.$router.back();
    },
    async createRole() {

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');


      const roleData = {
        userId,
        userRoleType: this.userRoleType,
        userRoleDescription: this.userRoleDescription
      };

      try {

        const response = await axios.post('/api/createUserRole', roleData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.status === 201) {
          this.showSnackbar("User role created successfully!", "success");
          this.getAllUserRoles();

        } else {
          this.showSnackbar("Unexpected response status.", "warning");
        }
        console.log('Role created successfully:', response.data);


        this.userRoleDialog = false;
        this.userRoleType = '';



      } catch (error) {
        console.error('Error creating role:', error);

      }
    },
    cancel() {
      this.userRoleType = '';
      this.userRoleDialog = false;
    },

  }
};
</script>

<style scoped>
.v-card-title {
  font-weight: bold;
}
.page-title {
  font-weight: bold;
}
.light-gray-text{
  color: #a09e9e !important;
}
.tool-bar-style {
  display: flex;
  justify-content: end;
}
.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: black;
}
.red-text {
  color: #b00d0d;
}
.divider-vertical{
  color: #3c3b3b !important;
}
.red-light-text {
  background-color: #f1cccc;
  color: #b00d0d;
}
.red-white-text {
  background-color: #b00d0d;
  color: white;
}
.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}
.green-text {
  color: #057b0a;
  background-color: #aee8b1;
}
.gray-icon-button {
  color: gray;
  background-color: #e2e2e2;
}
/* .leads-details-card{
  height: 100%;
} */
.rowClr{
  background-color: #d9d9d9;
}
.card-size{
  width: 100%;
  height: 100%;
  padding: 0;
  margin-bottom: 0;
}
.divider-two-style{
  margin-top: 11%;
}
.margin-bottomn-fix{
  justify-items: end;
  margin-top: 1%;
  display: flex;
}
.align-center {
  align-items: center;
}
.card-hight-fixed{
  height: 100%;
  overflow: auto;
  max-height: 54vh;
}
.row-width-full{
  width: 100%;
}
.textspace{
  margin-top: -20px;
}
.margin-up-down{
  margin-top: -40px;
  margin-bottom: -45px;
}
.margin-up-down-two{
  margin-top: -10px;
  margin-bottom: -40px;
}
</style>
    