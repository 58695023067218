<!-- Sales and collections analysis component -->

<template>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3">

          <v-row>
            <v-col cols="12">
              <v-card flat>
                <OutletMapDashboardComponentFour/>
              </v-card>
            </v-col>
            
          </v-row>
          </v-card>
    </v-col>
  </v-row>
</template>

<script>
import OutletMapDashboardComponentFour from "@/components/MAP/dashboard_outlet_map_component_four.vue";
export default {
    name: 'outletMap',
    components: {
      OutletMapDashboardComponentFour
    },

    data() {
      return {
          
      };
    },
    
    methods: {
    
    }
};
</script>

<style scoped>

</style>