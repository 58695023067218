<template>
    <v-card class="mt-3 card-size" flat>
      <v-row class="px-6">
        <v-col cols="7" class="mt-3">
          <h3 class="page-title">CREATE PRODUCTS CATEGORY</h3>
          <h5 class="light-gray-text">Create a new product category according to your requirements.</h5>
          <v-card-title>
            
          </v-card-title>
        </v-col>
        <v-col cols="5" class="px-4 mt-5">
          <v-row class="tool-bar-style">
              <v-divider vertical :thickness="3" class="divider-vertical"></v-divider>
              <v-btn variant="text" size="x-large" class="gray-text" @click="backPageRoute()">
                <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
              </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-card :variant="tonal" class="px-2 pt-2 mx-1  product-details-card">
        <h5 class="card-two-title px-3 mt-2 mb-4">CREATE NEW PRODUCT CATEGORIES</h5>
        <v-divider></v-divider>
  
        <v-row class="pt-6">
            <v-col cols="8" class="px-6">
                <v-card flat class="card-hight-fixed">
                    <v-text-field
                    class="my-2"
                    label="Main Category"
                    variant="outlined"
                    placeholder="Create main category"
                    v-model="mainCategory"
                    ></v-text-field>
                    <div v-for="(subCategory, index) in subCategories" :key="index">
                        <v-row class="mb-2">
                            <v-col cols="10">
                            <v-text-field v-model="subCategories[index]" label="Sub Category" variant="outlined" placeholder="Create sub category"></v-text-field>
                            </v-col>
                            <v-col cols="1" class="align-center justify-center">
                            <v-btn density="default" class="green-text" variant="outlined" icon="mdi-plus-thick" @click="addSubCategory"></v-btn>
                            </v-col>
                            <v-col cols="1" class="align-center justify-center">
                            <v-btn density="default" class="red-text-btn" variant="outlined" icon="mdi-minus-thick" @click="removeSubCategory(index)" v-if="subCategories.length > 1"></v-btn>
                            </v-col>
                        </v-row>
                    </div>

                    <v-textarea
                        class="mb-2"
                        label="Category Description"
                        variant="outlined"
                        placeholder="Write a description here..."
                        v-model="categoryDescription"
                    ></v-textarea>

                </v-card>

            </v-col>
            <v-col cols="4" class="d-flex flex-column justify-end">
                <img
                    class="custom-create-product-category"
                    :src="require('@/assets/ASSETS/ILLUSTRATOR/Dropdown menu-amico.png')"
                />
            </v-col>
        </v-row>
        <v-divider class="divider-two-style"></v-divider>
        <v-row class="margin-bottomn-fix pa-3 ma-3">
            <v-spacer></v-spacer>
            <v-btn variant="outlined" class="red-background-text" @click="createCategory">CREATE CATEGORY</v-btn>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <v-btn variant="outlined" class="gray-background-text" @click="cancel()">CANCEL</v-btn> 
        </v-row>    
      </v-card>

      <!-- Snackbar to show notifications -->
      <v-snackbar
        v-model="snackAlert.snackbar"
        :color="snackAlert.snackColor"
        :top="snackAlert.snackBarTop"
        :right="snackAlert.snackBarRight"
        timeout="3000"
      >
        {{ snackAlert.snackMessage }}
        
      </v-snackbar>
    </v-card>
</template>

<script>
import axios from 'axios';
    export default {
      name: 'addCategory',
      data() {
        return {
          mainCategory: '',
          subCategories: [''],
          categoryDescription: '',
          snackAlert: {
            snackbar: false, // Snackbar visibility
            snackColor: "", // Snackbar color
            snackMessage: "", // Snackbar message
            snackBarRight: false, // Snackbar position right
            snackBarTop: true,  // Snackbar position top
          },
        };
      },
      methods: {
        backPageRoute(){
            this.$router.back();
        },
        addSubCategory() {
            this.subCategories.push('');
        },
        removeSubCategory(index) {
            this.subCategories.splice(index, 1);
        },
        // createCategory() {
        //   const data = {
        //     mainCategory: this.mainCategory,
        //     subCategories: this.subCategories,
        //     categoryDescription: this.categoryDescription,
        //   };

        //   axios.post('/api/categories', data)
        //     .then(response => {
        //       if (response.status === 200) {
        //         this.showSnackbar('Category created successfully!', 'success');
        //       } else {
        //         this.showSnackbar('Unexpected response status.', 'warning');
        //       }
        //     })
        //     .catch(error => {
        //       console.error('Error creating category:', error);
        //       this.showSnackbar('Failed to create category.', 'error');
        //     });
        // },
        
        createCategory() {
          const userId = localStorage.getItem('userId');
          const token = localStorage.getItem('token');
          
           // Ensure subCategories is formatted correctly
            const formattedSubCategories = this.subCategories.map(subCategory => ({
              subcatagaryName: subCategory
            }));
            
          const data = {
            userId: userId,
            mainCatagary: this.mainCategory,
            // subCategories: this.subCategories,
            // subCatagary: JSON.stringify(this.subCategories),
            subCatagary: JSON.stringify(formattedSubCategories),
            mainCatagaryDescription: this.categoryDescription,
          };

          axios.post('/api/addCatagary', data, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          })
            .then(response => {
              if (response.status === 200) {
                this.showSnackbar('Category created successfully!', 'success');
                this.cancel(); // Reset form fields after successful creation
              } else {
                this.showSnackbar('Unexpected response status.', 'warning');
              }
            })
            .catch(error => {
              console.error('Error creating category:', error);
              this.showSnackbar('Failed to create category.', 'error');
            });
        },

        cancel() {
          // Reset form fields
          this.mainCategory = '';
          this.subCategories = [''];
          this.categoryDescription = '';
        },

        showSnackbar(message, color) {
          this.snackAlert.snackbar = true;
          this.snackAlert.snackMessage = message;
          this.snackAlert.snackColor = color;
        },


      },
      mounted() {
        // Ensure userId and token are available in local storage
        if (!localStorage.getItem('userId') || !localStorage.getItem('token')) {
          this.showSnackbar('User not authenticated.', 'error');
          this.$router.push('/login');
        }
      },

    };
</script>

<style>
  .card-size{
    width: 100%;
    padding: 0;
    margin-bottom: 0;
  }
  .page-title {
    font-weight: bold;
  }
  .light-gray-text{
    color: #a09e9e !important;
  }
  .tool-bar-style {
    display: flex;
    justify-content: end;
  }
  .divider-vertical{
    color: #3c3b3b !important;
  }
  .gray-text {
    color: #757575 !important;
  }
  .tool-bar-style {
    display: flex;
    justify-content: end;
  }
  .product-details-card{
    height: 100%;
  }
  .card-two-title{
    color: black;
  }
  .card-hight-fixed{
    height: 100%;
    overflow-y: auto;
    max-height: 63vh;
  }
  .green-text {
    color: #057b0a;
    background-color: #83d187;
    padding-left: 0;
  }
  .red-text-btn {
    color: #b00d0d;
    background-color: #f0a5a5;
  }
  .divider-two-style{
    margin-top: -5px;
  }
  .margin-bottomn-fix{
    margin-top: 0px;
  }
  .red-background-text {
    background-color: #f0bcbc;
    color: #BB0000;
  } 
  .gray-background-text {
    background-color: rgb(202, 202, 202);
    color: black;
  } 
  .custom-create-product-category {
    width: 300px;
    height: 300px;
    object-fit: contain;
    margin-left:auto;
    margin-right:auto;
  }
</style>