<template class="main">
  <v-card class="mt-3 card-size" flat>
    <v-row class="pl-2 pr-6">
      <v-col cols="6" class="mt-3">
        <h3 class="page-title">USER ROLES</h3>
        <h5 class="light-gray-text">User roles & their permissions registered in the system.</h5>
        <v-card-title>

        </v-card-title>
      </v-col>
      <v-col cols="6" class="px-4 mt-5">
        <v-row class="tool-bar-style">
          <v-btn size="x-large" class="red-text" variant="text"  @click="createUser()">
            <v-icon>mdi-account-plus</v-icon>&nbsp;&nbsp;CREATE USER
          </v-btn>
          <v-divider vertical :thickness="3" class="divider-vertical"></v-divider>
          <v-btn variant="text" size="x-large" class="gray-text" @click="backPageRoute()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-card elevation="3" class="mt-3 mb-10 leads-details-card">
      <h5 class="card-h4-title px-3 mt-2 mb-4" style="color:#666666 ">USERS ROLES
        <div class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-btn class="red-light-text mr-3" variant="outlined" @click="exportPDF">
            <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>

          <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
            <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </div>
      </h5>
      <v-divider></v-divider>
      <v-row class="px-3 pt-9">
        <v-col cols="12">
          <v-text-field
              v-model="searchQuery"
              append-inner-icon="mdi-magnify"
              variant="outlined"
              placeholder="Search"
              label="Search"
              hide-details
              clearable
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-3 pt-3">
        <v-card class="mt-3 mx-3  " style="width: 100%; height: 100%; margin-bottom: 20px; padding-bottom: 7%">
          <v-data-table
              :headers="headers"
              :items="filteredItems"
              items-per-page="5"
              class="data-table elevation-1 dataTable"
              item-value="name"
          >
            <template v-slot:[`item.roleType`]="{ item }">
              <span class="red-text">{{ item.roleType }}</span>
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <span class="description-text">{{ item.description }}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row>
                <v-btn
                    class="mr-3 bg-blue-lighten-5 viewbtn"
                    variant="outlined"
                    color="green"
                    @click="openEditDialog(item)"
                >
                  <v-icon>mdi-check-circle</v-icon>&nbsp;  Edit
                </v-btn>
                <v-btn
                    class="bg-red-lighten-5"
                    rounded
                    variant="outlined"
                    size="small"
                    color="red"
                    icon
                    @click="confirmDelete(item)"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </v-row>
            </template>

            <template v-slot:[`item.permissionLevel`]="{ item }">
              <v-chip :color="getPermissionColor(item.dashboard)">
                {{ getPrimaryPermission(item.dashboard) }}
                <span v-if="getSecondaryPermission(item.dashboard)">
                  ({{ getSecondaryPermission(item.dashboard) }})
                </span>
              </v-chip>
            </template>
          </v-data-table>
          <!-- <v-row>
            <v-col cols="12" class="text-right">
              <v-pagination
                v-model="page"
                :length="totalPages"
                @input="fetchProducts"
              ></v-pagination>
            </v-col>
          </v-row> -->
        </v-card>
      </v-row>
    </v-card>
    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteUserListDialog" max-width="500" max-height="500" persistent>
      <v-card class="pa-1">
        <v-row dense class="rowClr ma-1">
          <v-col cols="12" md="9">
            <v-card-title class="headline">DELETE USER ROLE</v-card-title>
          </v-col>
          <v-col cols="12" md="3" class="d-flex justify-end pt-3 pr-3">
            <v-btn icon size="x-small" @click="deleteUserListDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-divider class="mt-3"></v-divider>

        <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this user role ?</v-card-text>
        <v-card-text class="textspace">
          This user role will be deleted immediately. Once deleted, it can no longer be used in any purpose.
        </v-card-text>
        <v-divider class="mb-3"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="deleteItem" variant="outlined" class="bg-red-lighten-4">DELETE</v-btn>&nbsp; &nbsp;
          <v-btn @click="deleteUserListDialog = false" variant="outlined" class="bg-grey-lighten-2">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="edit_user_role_dialog" fullscreen>
      <v-card class="card-size">
        <v-toolbar dense color="white" class="pr-5 mb-5">
          <v-toolbar-title>
            <img @click="homeReload()" :src="require('@/assets/app_bar_logo.png')"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" variant="elevated" dark @click="edit_user_role_dialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-card class="pa-3 mx-6">
              <v-icon>mdi-view-dashboard
              </v-icon>
              <v-label class="ml-6">UPDATE USER ROLE</v-label>
            </v-card>
            <v-card class="pa-3 mx-6 mt-3">
              <v-row class="first_row ma-0 pa-0 ">
                <v-col cols="12" class="secondC textCenter" >
                  <v-spacer></v-spacer>
                  <div class="justify-right align-right ml-6">
                    <v-btn color="red" @click="updateUserRole()" variant="outlined" size="large" class="bg-red-lighten-4">UPDATE USER ROLE</v-btn>&nbsp; &nbsp;
                    <v-btn @click="edit_user_role_dialog = false" variant="outlined" size="large" class="bg-grey-lighten-2">CANCEL</v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="mt-3"></v-divider>
              <v-row class="row-width-full align-center">
                <v-col cols="6">
                  <v-row dense class="rowClrT mx-3 mb-3">
                    <v-col cols="1">
                      <v-card-text><v-icon size="x-large" color="orange">mdi-alert</v-icon></v-card-text>
                    </v-col>
                    <v-col cols="11">
                      <v-card-text>These user roles help you grant permissions and assign related functions to users in the system.</v-card-text>
                    </v-col>
                  </v-row>

                  <v-row class="mb-3 mt-6 mx-0">
                    <v-col cols="12">
                      <v-select
                          v-model="roleForm.roleType"
                          :items="userTypes"
                          label="Role type *"
                          variant="outlined"
                          placeholder="Select user role type"
                          item-title="name"
                          item-value="id"
                          required
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row class="mb-3 mt-3 mx-3">
                    <v-textarea
                        label="Role Description *"
                        v-model="roleForm.description"
                        variant="outlined"
                        placeholder="Type user description here"
                        required
                        rows="2"
                    ></v-textarea>
                  </v-row>
                </v-col>
                <v-divider vertical :thickness="3" class="divider-vertical my-6"></v-divider>
                <v-col cols="5" class="d-flex flex-column justify-end">
                  <h5 class="card-two-title px-3 mt-8 mb-4">Setup Permissions</h5>
                  <v-card class="pt-2 mb-3 mx-1 mt-1 card-hight-fixed" flat>

                    <v-row v-for="(permission, index) in roleForm.permissions" :key="index" class="align-center no-gutters">
                      <v-row class="ml-3">
                        <v-col cols="auto" class="d-flex margin-up-down-two">
                          <v-switch
                              v-model="permission.isOn"
                              :color="permission.isOn ? 'red' : 'grey'"
                              class="mr-1"
                              @change="handleSwitchChange(permission)"
                          ></v-switch>
                        </v-col>
                        <v-col cols="auto" class="d-flex">
                          <v-card-text :class="{ 'red-text': permission.isOn }" class="ml-3 mr-1">{{ permission.premission_dashboard }}</v-card-text>
                        </v-col>
                      </v-row>
                      <v-col cols="12" v-if="permission.isOn" class="pl-10">
                        <v-row class="margin-up-down">
                          <v-col cols="auto" class="d-flex">
                            <v-switch
                                v-model="permission.permissions.read"
                                color="red"
                                @change="handlePermissionChange(permission, 'read')"
                            ></v-switch>
                            <v-card-text class="mx-1">Read</v-card-text>
                          </v-col>
                          <v-col cols="auto" class="d-flex">
                            <v-switch
                                v-model="permission.permissions.write"
                                color="red"
                                @change="handlePermissionChange(permission, 'write')"
                            ></v-switch>
                            <v-card-text class="mr-1">Write</v-card-text>
                          </v-col>
                          <v-col cols="auto" class="d-flex">
                            <v-switch
                                v-model="permission.permissions.delete"
                                color="red"
                                @change="handlePermissionChange(permission, 'delete')"
                            ></v-switch>
                            <v-card-text class="mr-1">Delete</v-card-text>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-divider v-if="index < roleForm.permissions.length - 1"></v-divider>
                    </v-row>

                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>



    <!-- Snackbar to show notifications -->
    <v-snackbar
        v-model="snackAlert.snackbar"
        :color="snackAlert.snackColor"
        :top="snackAlert.snackBarTop"
        :right="snackAlert.snackBarRight"
        timeout="3000"
    >
      {{ snackAlert.snackMessage }}

    </v-snackbar>
  </v-card>
</template>

<script>
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
export default {
  name: 'UserRoleList',
  data() {
    return {
      search: '',
      headers: [
        { text: 'Role Type', value: 'roleType', title: 'Role Type' },
        { text: 'Creation Date', value: 'creationDate', title: 'Creation Date' },
        { text: 'Description', value: 'description', title: 'Description' },
        { text: 'Permission Level', value: 'permissionLevel', title: 'Permission Level' },
        { text: 'Action', value: 'action', sortable: false, title: 'Action' }
      ],

      isEditMode: false,
      roleForm: {
        id: null,
        roleType: '',
        description: '',
        permissions: [],
        dashboard:[]
      },
      items: [],


      relatedValue: null,

      // Pagination
      itemsPerPage: 10,
      page: 1,
      totalPages: 0,

      //dialog status
      deleteUserListDialog: false,
      edit_user_role_dialog: false,
      // selectedItem: null,
      roleToDelete: null,

      userRoles: [],
      userTypes: [],
      searchQuery: '',
      selectedItem: {
        id: null,
        user_role_id: null,
        description: '',
        dashboard: [],
      },
      deleteItemId: null,

      permissionsItems: [],

      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },
    };
  },
  mounted() {
    this.getAllUserRoles();
    this.getDashboardPermissions();
  },
  created() {
    this.fetchUserRoles();
  },
  computed: {
    filteredItems() {
      const query = this.searchQuery.toLowerCase();
      return this.items.filter(item => {
        return Object.values(item).some(val => String(val).toLowerCase().includes(query));
      });
    },
  },

  methods: {

    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    exportExcel() {
      const tableData = this.items.map((item, index) => ({
        '#': index + 1,
        'Role Type': item.roleType,
        'Creation Date': item.creationDate,
        'Description': item.description,
        // 'Permission Level': item.permissionLevel,
        // Combine the primary and secondary permissions
        'Permission Level': `${this.getPrimaryPermission(item.dashboard)}${this.getSecondaryPermission(item.dashboard) ? ` (${this.getSecondaryPermission(item.dashboard)})` : ''}`,
      }));

      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'User Role');


      XLSX.writeFile(workbook, 'user_role_analysis.xlsx');
    },

    exportPDF() {
      const doc = new jsPDF();

      // Set the title for the document
      doc.text('User Role Analysis', 14, 10);

      // Prepare the data for the table
      const tableData = this.items.map((item, index) => [
        index + 1,
        item.roleType,
        item.creationDate,
        item.description,
        // item.permissionLevel,
        // Combine the primary and secondary permissions
        `${this.getPrimaryPermission(item.dashboard)}${this.getSecondaryPermission(item.dashboard) ? ` (${this.getSecondaryPermission(item.dashboard)})` : ''}`,
      ]);

      // Define the columns for the table
      const tableColumns = ['#', 'Role Type', 'Creation Date', 'Description', 'Permission Level'];

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
      });

      // Save the PDF with a name
      doc.save('user_role_analysis.pdf');

    },

    getDashboardPermissions() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`/api/getDashboard?userId=${userId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the Authorization header with the Bearer token
            }
          })
          .then(response => {
            const dashboards = response.data.data;
            this.permissionsItems = dashboards.map(dashboard => ({
              id: dashboard.id,
              premission_dashboard: dashboard.premission_dashboard,
              isOn: false,
              permissions: { read: false, write: false, delete: false },
            }));
            console.log('Dashboard permissions:', this.permissionsItems);
          })
          .catch(error => {
            console.error('Error fetching dashboard permissions:', error);
          });
    },

    getAllUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/getUserRolesType', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            let usrRole = response.data.data;
            this.userTypes = usrRole.map(item => {
              return {
                id: item.id,
                name: item.role_type
              };
            });
            console.log('User role types:', this.userTypes);
          })
          .catch(error => {
            console.error('Error fetching user roles:', error);
          });
    },

    async fetchUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      try {
        const response = await axios.post('/api/allCreatedUserRoles', {
          userId: userId
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.data.success === "Get Dashboard successfully") {
          const roles = response.data.data;
          console.log('Roles data:', roles); // Log the roles data


          // this.items = roles.map(role => ({
          //   roleType: role.userrole ? role.userrole.role_type : 'N/A',
          //   creationDate: new Date(role.created_at).toLocaleDateString(),
          //   description: role.description || 'No description',
          //   // permissionLevel: this.formatPermissionLevel(role.dashboard)
          //   dashboard: role.dashboard || [],
          //   id: role.id,
          // }));

          // Sort the data by created_at in descending order (newest first)
          this.items = roles.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map(role => ({
            roleType: role.userrole ? role.userrole.role_type : 'N/A',
            creationDate: new Date(role.created_at).toLocaleDateString(),
            description: role.description || 'No description',
            // permissionLevel: this.formatPermissionLevel(role.dashboard)
            dashboard: role.dashboard || [],
            id: role.id,
          }));

          this.totalPages = Math.ceil(this.items.length / this.itemsPerPage);
        }
      } catch (error) {
        console.error('Error fetching user roles:', error);
        // Optionally handle errors or show user feedback
      }
    },

    formatPermissionLevel(dashboard) {
      if (dashboard.length === 0) return 'No Permissions';

      const permissions = dashboard.map(d => {
        let perms = [];
        if (d.read_premission) perms.push('Read');
        if (d.write_premission) perms.push('Write');
        if (d.delete_premission) perms.push('Delete');
        return perms.join(' / ');
      });

      return `Permissions for: ${permissions.join(', ')}`;
    },

    backPageRoute(){
      this.$router.back();
    },

    createUser(){
      this.$router.push({ path: '/user-registration' });
    },

    handleSwitchChange(permission) {
      if (!permission.isOn) {
        // Reset the permissions when the switch is turned off
        permission.permissions.read = false;
        permission.permissions.write = false;
        permission.permissions.delete = false;
      } else {
        // Optionally, you can enable default permissions here if needed
        // For example, you might want to enable read by default when the main switch is turned on
        permission.permissions.read = true;
      }
    },

    // handlePermissionChange(item, permissionType) {
    //   // You can perform additional logic here if needed
    //   // For example, you can ensure that if the 'delete' permission is enabled, 'read' and 'write' must also be enabled.
    //   if (permissionType === 'delete' && item.permissions.delete) {
    //     item.permissions.read = true;
    //     item.permissions.write = true;
    //   }

    //   // If both 'read' and 'write' are disabled, ensure 'delete' is also disabled
    //   if (!item.permissions.read && !item.permissions.write) {
    //     item.permissions.delete = false;
    //   }
    // },

    handlePermissionChange(permission, permissionType) {
      // Check if 'delete' permission is being modified
      if (permissionType === 'delete') {
        if (permission.permissions.delete) {
          // Enable 'read' and 'write' if 'delete' is enabled
          permission.permissions.read = true;
          permission.permissions.write = true;
        } else {
          // Ensure 'read' and 'write' are disabled if 'delete' is turned off
          if (!permission.permissions.read && !permission.permissions.write) {
            permission.permissions.delete = false;
          }
        }
      }

      // Check if 'read' or 'write' permissions are being modified
      if (permissionType === 'read' || permissionType === 'write') {
        if (!permission.permissions.read && !permission.permissions.write) {
          // Disable 'delete' if both 'read' and 'write' are turned off
          permission.permissions.delete = false;
        }
      }
    },


    getPermissionLevel(dashboard) {
      let hasRead = false;
      let hasWrite = false;
      let hasDelete = false;

      dashboard.forEach(dash => {
        if (dash.read_premission) hasRead = true;
        if (dash.write_premission) hasWrite = true;
        if (dash.delete_premission) hasDelete = true;
      });

      if (hasRead && hasWrite && hasDelete) {
        return 'All Permissions';
      } else if (hasRead && hasWrite) {
        return 'Permissions for Read and Write';
      } else if (hasRead) {
        return 'Permission for Read';
      } else if (hasWrite) {
        return 'Permission for Write';
      } else if (hasDelete) {
        return 'Permission for Delete';
      } else {
        return 'No Permissions';
      }
    },

    getPermissionColor(dashboard) {
      let hasRead = false;
      let hasWrite = false;
      let hasDelete = false;

      dashboard.forEach(dash => {
        if (dash.read_premission) hasRead = true;
        if (dash.write_premission) hasWrite = true;
        if (dash.delete_premission) hasDelete = true;
      });

      if (hasRead && hasWrite && hasDelete) {
        return 'red';
      } else if (hasRead && hasWrite) {
        return 'green';
      } else if (hasRead || hasWrite || hasDelete) {
        return 'blue';
      } else {
        return 'grey';
      }
    },
    getPrimaryPermission(dashboard) {
      let permission = this.getPermissionLevel(dashboard);
      if (permission === 'All Permissions') {
        return 'All Permissions';
      } else if (permission.startsWith('Permissions for')) {
        return permission.split(' ')[2];
      } else {
        return permission;
      }
    },
    getSecondaryPermission(dashboard) {
      let permission = this.getPermissionLevel(dashboard);
      if (permission === 'All Permissions') {
        return 'Read, Write, Delete';
      } else if (permission.startsWith('Permissions for')) {
        return permission.split(' ').slice(-2).join(' ');
      } else {
        return null;
      }
    },

    confirmDelete(item) {
      this.selectedItem = item;
      this.deleteItemId = item.id;
      this.deleteUserListDialog = true;
    },

    async deleteItem() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const userDiscriptionId = this.deleteItemId;

      console.log('Deleting item with ID:', userDiscriptionId);
      console.log('Deleting item with token:', token);
      console.log('Deleting item with userId:', userId);

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/deleteCreatedUserPrermission`, {
          userId,
          userDiscriptionId
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        console.log('API Response:', response.data); // Log API response

        if (response.data.success) {
          // Close the dialog and reset deletion state
          this.deleteUserListDialog = false;
          this.deleteItemId = null;
          this.showSnackbar("User role deleted successfully!", "success");

          // Refresh the data table
          await this.fetchUserRoles();
        } else {
          console.error('Failed to delete the user role:', response.data);
          this.showSnackbar("Failed to delete user role.", "error");
        }
      } catch (error) {
        console.error('Error deleting user role:', error);
        this.deleteUserListDialog = false;
        this.showSnackbar("Error deleting user role.", "error");
      }
    },


    // openEditDialog(item) {
    //   this.isEditMode = true;
    //   this.selectedItem = item;
    //   // this.roleForm = {
    //   //     id: item.id,
    //   //     roleType: item.roleType,
    //   //     description: item.description,
    //   //   };
    //   this.edit_user_role_dialog = true;
    //   console.log('Editing item:', item);
    // },

    // openEditDialog(item) {
    //   this.isEditMode = true;
    //   this.selectedItem = item;

    //   // Assuming you have a form object to hold the edit data
    //   this.roleForm = {
    //     id: item.id,
    //     roleType: item.roleType,
    //     description: item.description,
    //     permissions: item.dashboard.map(dashboardItem => ({
    //       dashboardId: dashboardItem.dashboard_id,
    //       permissionDashboard: dashboardItem.userdashboard.premission_dashboard,
    //       readPermission: dashboardItem.read_premission,
    //       writePermission: dashboardItem.write_premission,
    //       deletePermission: dashboardItem.delete_premission,
    //     })),
    //   };

    //   this.edit_user_role_dialog = true;
    //   console.log('Editing item:', this.roleForm);
    // },

    openEditDialog(item) {
      this.isEditMode = true;
      this.selectedItem = item;

      // Set role type and description
      this.roleForm = {
        roleType: item.roleType,
        description: item.description,
        permissions: this.permissionsItems.map(permission => {
          const existingPermission = item.dashboard.find(d => d.dashboard_id === permission.id);
          if (existingPermission) {
            return {
              id: permission.id,
              premission_dashboard: permission.premission_dashboard,
              isOn: true,
              permissions: {
                read: !!existingPermission.read_premission,
                write: !!existingPermission.write_premission,
                delete: !!existingPermission.delete_premission,
              },
            };
          }
          // Return the existing permission object as default
          return {
            id: permission.id,
            premission_dashboard: permission.premission_dashboard,
            isOn: false,
            permissions: {
              read: false,
              write: false,
              delete: false,
            },
          };
        }),
      };

      this.edit_user_role_dialog = true;
      console.log('Editing item:', item);
      console.log('Role Form:', this.roleForm);
    },

    updateUserRole() {
      const userId = parseInt(localStorage.getItem('userId'), 10);
      const token = localStorage.getItem('token');

      // Map the permissionsItems to the required format
      const dashboards = this.roleForm.permissions
          .filter(item => item.isOn) // Only include dashboards where the switch is ON
          .map(item => ({
            dashboardId: item.id, // Assuming item.id represents the dashboard ID
            readPremission: item.permissions.read,
            writePremission: item.permissions.write,
            deletePremission: item.permissions.delete,
          }));

      // // Map the permissionsItems to the required format
      // const dashboards = this.permissionsItems
      //   .filter(item => item.isOn) // Only include dashboards where the switch is ON
      //   .map(item => ({
      //     dashboardId: item.id,
      //     readPremission: item.permissions.read,
      //     writePremission: item.permissions.write,
      //     deletePremission: item.permissions.delete,
      //   }));

      // const payload = {
      //   userRoleId: this.selectedItem.user_role_id, // Map to userRoleId
      //   description: this.selectedItem.description, // Map to description
      //   accessDashboard: dashboards, // Use the transformed dashboards array
      //   userId,
      // };
      // const payload = {
      //   // userRoleId: this.selectedItem.id, // Use selectedItem's id if editing an existing role
      //   // userListId: this.selectedItem.id,
      //   userRoleId: parseInt(this.selectedItem.user_role_id, 10),
      //   roleType: this.roleForm.roleType, // Map to roleType
      //   description: this.roleForm.description, // Map to description
      //   accessDashboard: dashboards, // Use the transformed dashboards array
      //   userId,
      // };

      const roleType = this.userTypes.find(
          (outlet) => Number(outlet.id) === Number(this.roleForm.roleType)
      );


      console.log('Selected Role type:', roleType);

      const selectedRoleType = roleType ? roleType.role_type : '';

      const payload = {
        userListId: this.selectedItem.id,
        // userRoleId: this.selectedItem.user_role_id,
        userRoleId: this.roleForm.roleType,
        roleType: selectedRoleType,
        description: this.roleForm.description, // Correctly bind description
        accessDashboard: dashboards, // Use the merged dashboards array
        userId,
      };
      // Print the payload as a formatted JSON string
      console.log('Payload to be sent:', JSON.stringify(payload, null, 2));

      // Convert the payload to a JSON string for sending in the request
      const jsonPayload = JSON.stringify(payload);

      // Log the payload being sent
      console.log('Payload being sent:', jsonPayload);

      axios.post('/api/createNewUserPrermission', jsonPayload, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
          .then(response => {
            console.log('User role created:', response.data);
            this.fetchUserRoles();
            this.showSnackbar("User role permission created successfully!", "success");
            this.edit_user_role_dialog = false;
            // Handle successful creation (e.g., display a success message, reset form)
          })
          .catch(error => {
            console.error('Error creating user role:', error);
            // Handle error (e.g., display an error message)
          });
    },
  }
};
</script>

<style scoped>
.v-card-title {
  font-weight: bold;
}
.page-title {
  font-weight: bold;
}
.light-gray-text{
  color: #a09e9e !important;
}
.tool-bar-style {
  display: flex;
  justify-content: end;
}
.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: black;
}
.red-text {
  color: #b00d0d;
}
.divider-vertical{
  color: #3c3b3b !important;
}
.red-light-text {
  background-color: #f1cccc;
  color: #b00d0d;
}
.red-white-text {
  background-color: #b00d0d;
  color: white;
}
.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}
.green-text {
  color: #057b0a;
  background-color: #aee8b1;
}
.gray-icon-button {
  color: gray;
  background-color: #e2e2e2;
}
.dataTable {
  width: 100%;
  border: black;
  margin-bottom: 20px;
}
.description-text {
  display: block;
  width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.card-size{
  width: 100% !important;
  margin-bottom: 20px;
  overflow-y: hidden !important;
  height: 100%;
}
.rowClr{
  background-color: #d9d9d9;
}
.rowClrT{
  background-color: #d9d9d9;
  margin-top: -60px;
}
.textspace{
  margin-top: -20px;
}
.multiline-chip {
  display: flex;
  flex-direction: column; /* Ensure the divs stack vertically */
  white-space: normal; /* Allow text to wrap normally */
  padding: 8px; /* Adjust padding for a better look */
  line-height: 1.2em; /* Adjust line height for spacing between rows */
}
.secondary-permission {
  color: black; /* Set the secondary permission text color to black */
}
.secondary-permission-wrapper {
  text-align: center;
}

.first_row{
  display: flex;
  justify-content: flex-end;

}

.secondC{
  display: flex;

}

.textCenter {
  text-align: center;
}
.leads-details-card{
  height: 100%;
  overflow: auto;
  margin-bottom: 20px;
}
.divider-two-style{
  margin-top: -5px;
}
.margin-bottomn-fix{
  margin-top: 0px;
}
.align-center {
  align-items: center;
}
.card-hight-fixed{
  height: 100%;
  overflow: auto;
  max-height: 54vh;
}
.row-width-full{
  width: 100%;
}
.viewbtn{
  font-size: 12px;
}
:deep(.dataTable) th, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  text-align: center;
}

:deep(.dataTable) td, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
}

:deep(.dataTable) {
  border: white;
}

:deep(.dataTable )  tr,  th:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}
:deep(.dataTable )  tr,  td:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}
.margin-up-down{
  margin-top: -40px;
  margin-bottom: -45px;
}
.margin-up-down-two{
  margin-top: -10px;
  margin-bottom: -40px;
}
</style>
    