import {createRouter, createWebHistory} from "vue-router";
import Login from "@/components/Login.vue";
import ForgotPassword from "@/components/AUTH/forgot_password.vue";
import ResetPassword from "@/components/AUTH/reset_password.vue";
import DASHBOARD from "@/components/MAIN_DASHBOARD/main_dashboard.vue";
import App_Bar_and_Navigation_Drawer from "@/components/app_bar_and_navigation_drawer.vue";
import AddProducts from '@/components/PRODUCT_MANAGEMENT/AddProducts.vue';
import addCategory from '@/components/PRODUCT_MANAGEMENT/addCategory.vue';  
import ProductsList from '@/components/PRODUCT_MANAGEMENT/ProductsList.vue';  
import SalesManagement from '@/components/SALES_MANAGEMENT/Sales_Management.vue';  
import CollectionManagement from '@/components/COLLECTION_MANAGEMENT/Collection_Management.vue'; 
import OrganizationManagement from '@/components/OrganizationManagement/ORGANIZATION_MANAGEMENT.vue';  
import AddLeads from "@/components/LeadsManagement/AddLeads.vue";
import LeadsList from "@/components/LeadsManagement/LeadsList.vue";
import UserRegistration from "@/components/UserManagement/UserRegistration.vue";
import UsersList from "@/components/UserManagement/UsersList.vue";
import CreateUserRole from "@/components/UserManagement/CreateUserRole.vue";
import UserRoleList from "@/components/UserManagement/UserRoleList.vue";
import LiveTracking from "@/components/TrackingManagement/LiveTracking.vue";
import TrackingHistory from "@/components/TrackingManagement/TrackingHistory.vue";
import CreateRoutes from "@/components/RouteManagement/CreateRoutes.vue";
import RoutesList from "@/components/RouteManagement/RoutesList.vue";
import CreateOutlet from "@/components/OutletManagement/CreateOutlet.vue";
import OutletList from "@/components/OutletManagement/OutletList.vue";
import AllAgents from "@/components/TrackingManagement/AllAgents.vue";
import IdlingAgents from "@/components/TrackingManagement/IdlingAgents.vue";
import ParkingAgents from "@/components/TrackingManagement/ParkingAgents.vue";
import CheckList from "@/components/LeadsManagement/CheckList.vue";

const routes = [
    { path: '', component: Login },
    { path: '/login', component: Login },
    { path: '/forgot_password', component: ForgotPassword },
    { path: '/reset-password', component: ResetPassword },
    { path: '', component: App_Bar_and_Navigation_Drawer,
        children: [
            { path: '/dashboard', component: DASHBOARD },
            { path: '/add-products', component: AddProducts },
            { path: '/products-list', component: ProductsList },
            { path: '/products-category', component: addCategory },
            { path: '/add-leads', component: AddLeads },
            { path: '/leads-list', component: LeadsList },
            { path: '/user-registration', component: UserRegistration },
            { path: '/users-list', component: UsersList },
            { path: '/create-user-role', component: CreateUserRole },
            { path: '/user-role-list', component: UserRoleList },
            { path: '/sales-management', component: SalesManagement },
            { path: '/collection-management', component: CollectionManagement },
            { path: '/live-tracking', component: LiveTracking },
            { path: '/tracking-history', component: TrackingHistory },
            { path: '/create-routes', component: CreateRoutes },
            { path: '/routes-list', component: RoutesList },
            { path: '/organization-management', component: OrganizationManagement },
            { path: '/create-outlet', component: CreateOutlet },
            { path: '/outlet-list', component: OutletList },
            { path: '/all-agents', component: AllAgents },
            { path: '/idling-agents', component: IdlingAgents },
            { path: '/parking-agents', component: ParkingAgents},
            { path: '/check_list', component: CheckList},
        ],
    },

];



const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router
