<template >
  <v-card style="height: 100%; width: 100%;">
    <v-card class="pa-3 mx-6">
      <v-icon>mdi-view-dashboard
      </v-icon>
      <v-label class="ml-6">UPDATE SALES RECORD</v-label>
    </v-card>

    <v-row class="first_row ma-0 pa-0 ">
      <v-col class="secondC textCenter" cols="12" md="8">
        <div class="image">
          <v-img
              :src="imageSrc"
              class="rounded-circle"
          ></v-img>
        </div>
        <v-card class="dataCard" flat style=" padding: 20px; width: 30vw"  >
          <v-row>
            <v-col cols="12">
              <v-row><h4><span >User Name </span><span>  : </span><span style="color: red">{{ agentName }}</span></h4></v-row>
              <v-row><h5><span >Mobile </span><span style="margin-left: 39px"> : </span><span>{{ mobileNumber }}</span></h5></v-row>
              <v-row>
                <v-chip class="mt-3 pa-3" color="green" size="small"  text-color="white" variant="outlined" >
                  <v-icon  color="green"  >mdi-check-circle-outline</v-icon>
                  USER TYPE  - <span></span><span style="font-size: 16px; margin-left: 4px">{{userType}}</span> </v-chip>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

      </v-col>

      <v-col class="pdfC" cols="12" md="4">
        <div class="action">
          <v-btn class="editB bg-red-lighten-4 ml-5" color="red" icon rounded size="small" variant="outlined" width="200" @click="createRoute"> UPDATE SALES DATA
          </v-btn>
          <v-btn class="bg-grey-lighten-2 ml-3" color="grey" icon  rounded size="small" variant="outlined" width="150" @click="cancell">CANCEL
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-card style="width: 100%; height: 100%" >
    <v-row class="second_row ">
      <v-col cols="12" md="6">
        <v-card  class="pt-2 card-height-fixed" flat>
        <v-row class="textfield">
          <v-text-field
              v-model="oderId"
              label="Oder ID"
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="agentName"
              label="User Name"
              variant="outlined"
          ></v-text-field>
          <v-select
              v-model="userType"
              :items="outletTypes"
              item-title="role_type"
              item-value="role_type"
              label="Select Outlet Type"
              variant="outlined"
          ></v-select>
          <v-select
              v-model="province"
              :items="provinces"
              :loading="mDistrictLoading"
              label="Province"
              placeholder="Select province"
              variant="outlined"
              @update:modelValue="getAllDistrictAccProv"
          ></v-select>

          <v-select
              v-model="district"
              :items="districts"
              :loading="mDistrictLoading"
              label="District"
              placeholder="Select district"
              variant="outlined"
              @update:modelValue="getAllDsd"
          ></v-select>

          <v-select
              v-model="dsd"
              :items="dsdList"
              :loading="mDSDLoading"
              label="DSD"
              placeholder="Select DSD"
              variant="outlined"
              @update:modelValue="getAllGnd"

          ></v-select>

          <v-select
              v-model="gnd"
              :items="gndList"
              label="GND"
              placeholder="Select GND"
              variant="outlined"
          ></v-select>

          <v-text-field
              v-model="storeName"
              label="Store Name"
              variant="outlined"
          ></v-text-field>
        </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" >
        <v-card  class="pt-2 card-height-fixed" flat>
        <v-row class="textfield2">
          <v-text-field
              v-model="quantitySold"
              label="Quantity Sold"
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="salesAmount"
              label="Total Sales"
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="storeAddress"
              label="Sales Address"
              variant="outlined"
          ></v-text-field>

          <v-card>
            <v-tabs v-model="tab" stacked>
              <v-tab v-if="access.cash" class="text-red" value="tab-1" variant="plain">CASH</v-tab>
              <v-tab v-if="access.bankDeposit" class="ml-3 mr-5 text-red" value="tab-2" variant="plain">BANK DEPOSIT SLIP OR CHEQUE</v-tab>
              <v-tab v-if="access.payLater" class="text-red" value="tab-3" variant="plain">PAY LATER</v-tab>
            </v-tabs>

            <v-tabs-window v-model="tab" style="margin: 10px">
              <!-- Cash Tab -->
              <v-tabs-window-item  v-if="access.cash" value="tab-1">
                <v-text-field
                    v-model="salesAmount"
                    class="mt-4"
                    label="Sales Amount"
                    variant="outlined"
                ></v-text-field>
              </v-tabs-window-item>

              <!-- Bank Deposit Slip or Cheque Tab -->
              <v-tabs-window-item  v-if="access.bankDeposit" value="tab-2">
                <v-card class="d-flex align-center">
                  <v-icon class="mr-3" color="red">mdi-file</v-icon>
                  <div>
                    <v-btn
                        :href="fileUrl"
                        class="d-flex align-center"
                        download
                        variant="text"
                    >
                      bank-slip-01.png
                      <v-icon class="ml-1">mdi-download</v-icon>
                    </v-btn>
                    <div class="text-caption grey--text">
                      • file size – 92 kb
                    </div>
                  </div>

                </v-card>
              </v-tabs-window-item>

              <!-- Pay Later Tab -->
              <v-tabs-window-item v-if="access.payLater" value="tab-3">
                <v-text-field
                    v-model="oderId"
                    class="mt-2"
                    label="Order ID"
                    variant="outlined"
                ></v-text-field>
                <v-text-field
                    v-model="salesAmount"
                    class="mt-2"
                    label="Sales Amount"
                    variant="outlined"
                ></v-text-field>
              </v-tabs-window-item>
            </v-tabs-window>
          </v-card>

          <v-card-title >
            <h4 >Sales Products List</h4>
            <v-card-subtitle>
              <h6 >The following table shows all the products related to sales </h6>
            </v-card-subtitle>
          </v-card-title>
          <v-card class="tCard" variant="outlined">
            <v-col cols="12">
              <v-data-table
                  :headers="headers"
                  :items="salesProducts[0]?.products"
                  style="font-size: 12px"
              >
                <!-- Custom slot for product column -->
                <template v-slot:[`item.product_name`]="{ item }">
                  <span v-if="!item.isEditing" class="text-red">{{ item.product_name }}</span>
                  <v-text-field
                      v-else
                      v-model="item.product_name"
                      class="text-red"
                      dense
                      hide-details
                  />
                </template>

                <template v-slot:[`item.quantity`]="{ item }">
                  <span v-if="!item.isEditing">{{ item.quantity }}</span>
                  <v-text-field
                      v-else
                      v-model="item.quantity"
                      dense
                      hide-details
                  />
                </template>


                <template v-slot:[`item.sales_amount`]="{ item }">
                  <span v-if="!item.isEditing">{{ item.sales_amount }}</span>
                  <v-text-field
                      v-else
                      v-model="item.sales_amount"
                      dense
                      hide-details
                  />
                </template>

                <template v-slot:[`item.unit_price`]="{ item }">
                  <span v-if="!item.isEditing">{{ item.unit_price }}</span>
                  <v-text-field
                      v-else
                      v-model="item.unit_price"
                      dense
                      hide-details
                  />
                </template>



                <!-- Custom slot for actions column -->
                <template v-slot:[`item.action`]="{ item }">
                  <v-row>
                    <v-btn
                        v-if="!item.isEditing"
                        class="editB bg-green-lighten-4"
                        color="green"
                        icon
                        rounded
                        size="ex-small"
                        variant="outlined"
                        @click="editItem(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="item.isEditing"
                        class="saveB bg-green-lighten-4"
                        color="green"
                        icon
                        rounded
                        size="ex-small"
                        variant="outlined"
                        @click="saveItem(item)"
                    >
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="!item.isEditing"
                        class="bg-red-lighten-4 ml-2"
                        color="red"
                        icon
                        rounded
                        size="ex-small"
                        variant="outlined"
                        @click="CDelete(item)"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="item.isEditing"
                        class="cancelB bg-red-lighten-4 ml-2"
                        color="red"
                        icon
                        rounded
                        size="ex-small"
                        variant="outlined"
                        @click="cancelEdit(item)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                </template>
              </v-data-table>

            </v-col>
          </v-card>
        </v-row>
        </v-card>
      </v-col>
    </v-row>
    </v-card>
    <v-row>

    </v-row>
    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteCDialog" max-height="500" max-width="500" persistent>
      <v-card class="pa-1">
        <v-row dense style="background-color: #d9d9d9;">
          <v-col cols="12" md="10">
            <v-card-title class="headline">DELETE SALES DATA</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-btn class="mt-2 mr-2" icon size="x-small" @click="deleteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this product record ?</v-card-text>
        <v-card-text class="font-weight-medium">
          This product record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteOutlets">DELETE</v-btn>
          <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteCDialog = false">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" max-height="500" max-width="500" persistent>
      <v-card class="pa-1">
        <v-row dense style="background-color: #d9d9d9;">
          <v-col cols="12" md="10">
            <v-card-title class="headline">DELETE SALES DATA</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-btn class="mt-2 mr-2" icon size="x-small" @click="deleteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this product record ?</v-card-text>
        <v-card-text class="font-weight-medium">
          This product record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>
          <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteDialog = false">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        color="success"
        location="top-right"
        outlined
        timeout="3000"
    >
      UPDATE SALES DATA SUCCESSFULLY.
      <template v-slot:action="{ attrs }">
        <v-btn
            icon
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay v-model="loading" >
      <v-progress-circular class="centered-progress" indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-card>
</template>

<script>
import {EventBus} from "@/plugins/event-bus";
import axios from "axios";
import {mapGetters} from "vuex";
import Swal from "sweetalert2";


export default {
  name: "Sales_Record.vue",
  data() {
    return {
      loading: false,
      tab:null,
      snackbar: false,
      deleteDialog: false,
      itemToDelete: null,
      closeDialog: false,
      editDialog: false,
      CitemToDelete: null,
      deleteCDialog: false,
      fetchData:'',
        oderId:'',
        userType:'',
        province:'',
        district:'',
        dsd:'',
        gnd:'',
        quantitySold:'',
        salesAmount:'',
        storeAddress:'',
        storeName:'',
        userImage:'',
        mobileNumber:'',
        agentId:'',
        bankSlip:null,
        agentName:'',
      paymentMethod:'',
      products:[],


      outletTypes: [],
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
      mDistrictLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      headers: [
        {text: 'Product', value: 'product_name', title: 'Product'},
        {text: 'Quantity', value: 'quantity', title: 'Quantity'},
        {text: 'Sales Amount', value: 'sales_amount', title: 'Sales Amount'},
        {text: 'Unit Price', value: 'unit_price', title: 'Unit Price'},
        {text: 'Actions', value: 'action', sortable: false, title: 'Action' },
      ],
      salesProducts: [],
      access: {
        cash: false,
        bankDeposit: false,
        payLater: false,
      },
      changedProducts: [],

    };
  },

  methods: {
    editItem(item) {
      item.isEditing = true;
    },
    saveItem(item) {
      item.isEditing = false;
      // Check if the item already exists in changedProducts array
      const existingItem = this.changedProducts.find(p => p.id === item.id);
      if (existingItem) {
        // Update the existing item in the changedProducts array
        Object.assign(existingItem, item);
      } else {
        // Add the new changed item to the changedProducts array
        this.changedProducts.push({ ...item });
      }
    },
    cancelEdit(item) {
      item.isEditing = false;
      // Optionally reset the item's value if needed
      // this.resetItem(item);
    },
    cDelete() {

    },
    fetchOutletType(){
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/getUserRolesType`, { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.outletTypes = response.data.data;
            console.log('qqq', this.outletTypes)
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });

    },
    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mDistrictLoading = false;
            }
          })
          .catch(() => {
            this.mDistrictLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', value: province }, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', value: district }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', value: dsd }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGNDLoading = false;
            }
          })
          .catch(() => {
            this.mGNDLoading = false;
          });
    },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;

    },
    deleteItem() {
      // Implement your delete item logic here
      this.sales = this.sales.filter(sale => sale !== this.itemToDelete);
      this.deleteDialog = false;
      this.itemToDelete = null;
    },
    CDelete(item) {
      this.CitemToDelete = item;
      this.deleteCDialog = true;
    },
    deleteOutlets() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/sale/product/delete/${this.CitemToDelete.id}`,{ userId },{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.salesProducts[0].products = this.salesProducts[0].products.filter(sale => sale.id  !== this.CitemToDelete.id);
            console.log('fk',this.salesProducts);
            this.deleteCDialog = false;
            this.CitemToDelete = null;
          })
          .catch(error => {
            console.error("There was an error deleting the sale!", error);
          });
    },
    exportPDF() {
      //  functionality to export table data as PDF
    },
    cancell() {
      //  functionality to edit item details
      EventBus.emit('SalesRecodeManagementButtonClick2');
    },

    closeD() {
      this.viewDialog = false;
      this.editDialog = false;
    },
    async createRoute() {
      this.loading = true;

      // Combine original salesProducts and changedProducts into one array
      const combinedProducts = this.salesProducts[0].products.map(item => {
        const changedItem = this.changedProducts.find(p => p.id === item.id);
        return changedItem ? changedItem : item;
      });

      // Now you can pass the combinedProducts array to the backend
      console.log('Submitting to backend:', combinedProducts);
      // Example: this.$http.post('/api/submit', combinedProducts);


      const userId = localStorage.getItem('userId');
      const id = this.sharedData;
      console.log('Shared Data:', this.sharedData);

      const outletData = {
        order_id: this.oderId,
        store_name: this.storeName,
        user_type: this.userType,
        agent_id: String(this.agentId),
        address: this.storeAddress,
        quantity_sold: this.quantitySold,
        province: this.province,
        district: this.district,
        dsd: this.dsd,
        gnd: this.gnd,
        sales_amount: this.salesAmount,
        payment_method: this.paymentMethod,
        bank_slip_file: this.bankSlip,
        products: combinedProducts,
        id: id,
      };

      try {
        const token = localStorage.getItem('token');

        const response = await axios.post(
            `${process.env.VUE_APP_API_BASE_URL}/api/sales/save`,
            { ...outletData, userId },
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
        )
            .catch(error => {
              console.error("There was an error deleting the sale!", error);
            });

        console.log(response);

        this.snackbar = true;


      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response?.data?.message || 'Something went wrong!',
        });
      }finally {
        this.loading = false;
        setTimeout(() => {
          EventBus.emit('SalesRecodeManagementButtonClick2');
          this.editDialog = false;
        }, 3000);
      }
    },


    fetchSalesRecodeData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedData;

      console.log('Shared Data:', this.sharedData);
      console.log('fk', id);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/sales/${id}`, { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
          .then(response => {
            console.log("API Response all:", response.data);
            if (response.data.sale) {
              // Convert the sales object into an array with a single element if necessary
              this.salesProducts = Array.isArray(response.data.sale) ? response.data.sale : [response.data.sale];
              console.log("this.salesProducts:", this.salesProducts);

              if (this.salesProducts.length > 0) {
                const sale = this.salesProducts[0];
                this.agentName = sale.agent.name;
                this.oderId = sale?.order_id;
                this.userType = sale.user_type;
                this.quantitySold = sale?.quantity_sold;
                this.salesAmount = sale?.sales_amount;
                this.storeAddress = sale?.address;
                this.storeName = sale?.store_name;
                this.userImage = sale?.agent?.user_image;
                this.agentId = sale?.agent_id;
                this.mobileNumber = sale?.agent?.user_image;
                this.province = sale.province;
                this.district = sale.district;
                this.dsd = sale.dsd;
                this.gnd = sale.gnd;
                this.mobileNumber = sale.agent.mobile_number.length > 0 ? sale.agent.mobile_number[0].mobile_number : null;



                console.log("sahan", this.mobileNumber)

                // Check the payment method and set access flags
                this.paymentMethod = sale.payment_method;

                console.log(
                    "paymentMethod:", this.paymentMethod,
                )

                // Reset access flags
                this.access = {
                  cash: false,
                  bankDeposit: false,
                  payLater: false,
                };

                // Set access based on the payment method
                if (this.paymentMethod === 'cash') {
                  this.access.cash = true;
                  this.tab = 'tab-1'; // Set to cash tab
                } else if (this.paymentMethod === 'bank_deposit') {
                  this.access.bankDeposit = true;
                  this.tab = 'tab-2'; // Set to bank deposit tab
                } else if (this.paymentMethod === 'pay_later') {
                  this.access.payLater = true;
                  this.tab = 'tab-3'; // Set to pay later tab
                }
              }
            } else {
              this.salesProducts = [];
            }
          })
          .catch(error => {
            console.log(error);
            this.salesProducts = [];
          });
    }
    // this.mobileNumber = sale.agent.mobile_number.length > 0 ? sale.agent.mobile_number[0].mobile_number : null;
  },

  computed: {
    ...mapGetters(['sharedData']),
    imageSrc() {
      return `${process.env.VUE_APP_API_BASE_URL}${this.userImage}`;
    }
  },
  created() {
    this.fetchSalesRecodeData();

    // EventBus.on('viewAgentDetails',(data) =>{
    //   console.log('sahan', data.Id);
    // });
  },

  // beforeUnmount() {
  //   EventBus.off('viewAgentDetails', fetchData =>{
  //     console.log('pppppp', fetchData);
  //   });
  // },
  mounted() {
    this.fetchSalesRecodeData();
    this.fetchOutletType();
    this.getAllProvinces();
  },
  watch: {
    'province'(newValue) {
      this.getAllDistrictAccProv(newValue);
    },
    'district'(newValue) {
      this.getAllDsd(newValue);
    },
    'dsd'(newValue) {
      this.getAllGnd(newValue);
    }
  }

};
</script>


<style scoped>


.pdfC{
  display: flex;
  margin-top: 40px;
}

.editB{
  display: flex;
  margin-right: 10px;
}
.image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden; /* Ensure the image does not overflow the div */
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
}

.rounded-circle {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire div */
  display: block;
}

.textCenter {
  text-align: center;
}

.mt-4 {
  margin-top: 1.5rem;
}
.mt-2 {
  margin-top: 1rem;
}
.my-4 {
  margin: 1.5rem 0;
}

.textfield{
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-left: 50px;
  margin-top: 20px;
}

.textfield2{
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-left: 50px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.action {
  margin-left: 10px;
  display: flex;
  justify-content: right;
}

.first_row{
  display: flex;
  justify-content: flex-end;

}

.second_row{
  margin-right: 50px;
}
.secondC{
  display: flex;

}
.dataCard{
  display: flex;
  justify-content: left;
}

.tCard{
  height: auto;
}


.card-height-fixed {
  height: 100%;
  overflow: auto;
  max-height: 63vh;
}


</style>
