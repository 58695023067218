<!-- Forgot Password component -->
<template>
    <v-app>
      <!-- Container to hold the layout, making it fluid and taking the full height -->
      <v-container fluid class="pa-0 ma-0 d-flex fill-height">
        <v-row no-gutters class="fill-height">
          <!-- Image Section -->
          
          <v-col
          cols="12"
          xl="5"
          lg="5"
          md="12"
          sm="12"
          xs="12"
          class="image-container pa-0 ma-0"
        >
          <img
            class="custom_web_logo"
            :src="require('@/WEB/ASSETS/bc_img_2.png')"
            cover
          />
          <img
            class="overlay_logo"
            :src="require('@/WEB/LOGOS/logo1.png')"
          />
        </v-col>
  
          <!-- Forgot Password Form Section -->
          <v-col
            cols="12"
            xl="7"
            lg="7"
            md="12"
            sm="12"
            xs="12"
            class="d-flex align-center justify-center pa-0 ma-0"
          >
            <v-container color="white" class="fill-height" fluid>
              <v-row align="center" justify="center">
                <v-col cols="12" sm="12" md="2"></v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-card color="white" elevation="0">
                    <v-card-title class="font-weight-bold">
                      Forgot your password?
                    </v-card-title>

                    <v-card-subtitle class="mt-5">
                      <h4>
                        <v-icon left small>mdi-account-arrow-right-outline</v-icon>&nbsp;
                        Don't worry ! We will send you an email so you can reset your password.
                      </h4>
                    </v-card-subtitle>
  
                    <v-card-text class="mt-2" >
                      <v-form v-model="valid" ref="form">

                        <!-- Email input field with validation rules -->
                        <v-text-field
                          label="&nbsp;&nbsp;Email"
                          prepend-inner-icon="mdi-email-outline"
                          placeholder="Enter your e-mail address"
                          type="email"
                          :value="email"
                          v-model="email"
                          dense
                          :error-messages="email_errors"
                          :rules="emailRules"
                          required
                          outlined
                          @keyup.enter="resetPassword"
                          class="mb-0 mt-2 mb-3"
                          variant="outlined"
                        ></v-text-field>
  
                        <!-- Alert to show if email is invalid -->
                        <v-alert
                          v-if="showAlert"
                          type="error"
                          dismissible
                          @click:close="showAlert = false"
                        >
                          Please double-check and enter a valid email.
                        </v-alert>

                        <v-divider></v-divider>
  
                        <v-row class="black--text">

                          <!-- Continue button to trigger password reset -->
                          <v-col cols="6">
                            <v-btn
                              @click="resetPassword"
                              size="large"
                              block
                              color="error"
                              class="white--text mt-5"
                            >
                              <v-icon left>mdi-account-arrow-right-outline</v-icon>
                              &nbsp;Continue
                            </v-btn>
                          </v-col>

                          <!-- Button to go back to the login page -->
                          <v-col cols="6">
                            <v-btn
                              @click="backToLogIn"
                              size="large"
                              block
                              class="mt-5"
                              variant="tonal"
                            >
                            <v-icon left small>mdi-keyboard-backspace</v-icon>&nbsp;
                              &nbsp;Back to Login
                            </v-btn>
                          </v-col>
  
                        </v-row>
                        
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="2"></v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>

        <!-- Dialog box for inform about reset email has sent -->
        <v-dialog v-model="dialog_components" max-width="450px">
          <v-card class="text-left" >
              <v-container color="white" class="align-left justify-left">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-card color="white" flat>
                      <v-card-title class="font-weight-bold">
                          Password Reset Request
                      </v-card-title>
                      <v-card-subtitle class="mt-5 text-wrap">
                        <h4 class="mb-4">
                          Instructions to reset your password have been sent to your email.
                        </h4>
                        <h4>
                          Please check your email...!
                        </h4>
                      </v-card-subtitle>
    
                      <v-row class="black--text">
                          <v-col cols="12">
                          <v-btn
                              @click="backToLogIn"
                              size="large"
                              block
                              color="error"
                              class="white--text mt-5"
                          >
                          <v-icon left small>mdi-keyboard-backspace</v-icon>
                          &nbsp;Back to Login
                          </v-btn>
                          </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
          </v-card>
        </v-dialog>
      </v-container>
  
      <!-- Snackbar to show notifications -->
      <v-snackbar
        v-model="snackAlert.snackbar"
        :color="snackAlert.snackColor"
        :top="snackAlert.snackBarTop"
        :right="snackAlert.snackBarRight"
        timeout="3000"
      >
        {{ snackAlert.snackMessage }}
        
      </v-snackbar>
    </v-app>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    name: "ForgotPassword",
    data() {
      return {
        valid: false, // Form validation state
        dialog_components: false, // State to show/hide the dialog
        emailRules: [
          v => !!v || "E-mail is required",
          v =>
            /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,5})+$/.test(v) ||
            "E-mail must be valid"
        ],
        email_errors: [], // Array to hold email validation errors
        email: "", // User input for email
        snackAlert: {
          snackbar: false, // Snackbar visibility
          snackColor: "", // Snackbar color
          snackMessage: "", // Snackbar message
          snackBarRight: false, // Snackbar position right
          snackBarTop: true,  // Snackbar position top
        },
      };
    },
    methods: {
      // Navigate back to the login page
      backToLogIn() {
        this.$router.push('/');
      },
      showSnackbar(message, color) {
        this.snackAlert.snackMessage = message;
        this.snackAlert.snackColor = color;
        this.snackAlert.snackbar = true;
      },
      // Trigger the password reset process
      async resetPassword() {
        if (this.valid) {
          try {
            const response = await axios.post('/api/forgetPassword', { email: this.email });

            if (response.data.success) {
              this.dialog_components = true;
            } else {
              this.showSnackbar("Failed to send reset email. Please try again.", "error");
            }
          } catch (error) {
            if (error.response) {
              const errorMsg = error.response.data.message || "Failed to send reset email.";
              this.showSnackbar(errorMsg, "error");
            } else {
              this.showSnackbar("An error occurred. Please try again later.", "error");
            }
          }
        } else {
          this.showSnackbar("Please enter a valid email address.", "error");
        }
      },
    
    }
  };
  </script>
  
  <style scoped>
  .image-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Full height */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Prevent scrolling */
    background-color: #f5f5f5; /* Background color to match provided UI */
  }
  
  .custom_web_logo {
    width: 100%;
    height: 100%;
  }
  
  .fill-height {
    height: 100vh; /* Full viewport height */
  }
  
  .pa-0 {
    padding: 0 !important;
  }
  
  .ma-0 {
    margin: 0 !important;
  }
  
  @media (max-width: 960px) {
    .image-container {
      display: none; /* Hide the image on smaller screens */
    }
  }
  
  .text-right {
    text-align: right;
  }
  .overlay_logo {
    position: absolute;
    top: 45px;
    left: 45px;
    width: 220px;
    height: auto;
  }
  </style>
  