<template >
  <v-card style="height: 100%; width: 100%;">

    <div style="display: flex; justify-content: center;">
      <v-card class="pa-3 mt-3" style="width: 93%;">
        <v-icon class="ml-8" >mdi-view-dashboard
        </v-icon>
        <v-label class="ml-10">OUTLETS DETAILS</v-label>
      </v-card>
    </div>

    <div style="display: flex; justify-content: center;">
      <v-card class="pa-2 mt-3 mb-5" style="width: 93%; height: 100%; padding-bottom: 20px;">
        <v-row class="first_row ma-0 pa-0 ">
          <v-col class="secondC textCenter" cols="12" md="10">
            <div class="image">
              <v-img
                  :src="require('@/assets/ICONS/shop.png')"
                  class="rounded-circle"
              ></v-img>
            </div>
            <v-card class="dataCard" flat style=" padding: 20px; width: 30vw"  >
              <v-row>
                <v-col cols="12">
                  <v-row><h4><span >Outlet Name </span><span>  : </span><span style="color: red">{{ outletName }}</span></h4></v-row>
                  <v-row><h5><span >Mobile </span><span style="margin-left: 39px"> : </span><span>{{ mobileNumber }}</span></h5></v-row>
                  <v-row>
                    <v-chip class="mt-3 pa-3" color="green" size="small" style="width: auto" text-color="white" variant="outlined" >
                      <v-icon  color="green"  >mdi-check-circle-outline</v-icon>
                      OUTLET STATES  - <span></span><span style="font-size: 14px; margin-left: 4px">{{outletStates}}</span> </v-chip>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>

          </v-col>

          <v-col class="pdfC d-flex justify-center" cols="12" md="2">
            <div class="action">
              <v-btn class="editB bg-green-lighten-4" color="green" icon rounded size="small" variant="outlined" @click="editViewOutlet">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn class="bg-red-lighten-4" color="red" icon rounded size="small" variant="outlined" @click="confirmDelete">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-card flat style="width: 100%; height: 100%; margin-top: 10px; padding-bottom: 30%; padding-right: 20px" >
          <v-row>
            <v-col cols="12" md="6">
              <v-card class="pt-2 card-hight-fixed" flat>
                <v-row class="textfield">
                  <v-text-field
                      v-model="outletName"
                      label="Outlet Name"
                      readonly
                      variant="outlined"

                  ></v-text-field>
                  <v-text-field
                      v-model="ownerName"
                      label="Owner Name"
                      readonly
                      variant="outlined"

                  ></v-text-field>
                  <v-text-field
                      v-model="mobileNumber"
                      label="Mobile Number"
                      readonly
                      variant="outlined"

                  ></v-text-field>
                  <v-text-field
                      v-model="outletType"
                      label="Outlet Type"
                      readonly
                      variant="outlined"

                  ></v-text-field>
                  <v-text-field
                      v-model="province"
                      label="Province"
                      readonly
                      variant="outlined"

                  ></v-text-field>
                  <v-text-field
                      v-model="district"
                      label="District"
                      readonly
                      variant="outlined"

                  ></v-text-field>
                  <v-text-field
                      v-model="dsd"
                      label="DSD"
                      readonly
                      variant="outlined"

                  ></v-text-field>
                  <v-text-field
                      v-model="gnd"
                      label="GND"
                      readonly
                      variant="outlined"

                  ></v-text-field>
                  <v-text-field
                      v-model="outletStates"
                      label="Outlet States"
                      readonly
                      variant="outlined"

                  ></v-text-field>
                  <v-card
                      width="100%"
                  >
                    <v-card-title style="font-size: 10px">
                      Note (if the outlet states has been changed the reason)
                    </v-card-title>
                    <div class="d-flex justify-space-between pa-4 pb-0">
                      <v-btn-toggle
                          v-model="formatting"
                          divided
                          multiple
                          variant="outlined"
                      >
                        <v-btn>
                          <v-icon icon="mdi-format-italic"></v-icon>
                        </v-btn>

                        <v-btn>
                          <v-icon icon="mdi-format-bold"></v-icon>
                        </v-btn>

                        <v-btn>
                          <v-icon icon="mdi-format-underline"></v-icon>
                        </v-btn>

                        <v-btn>
                          <div class="d-flex align-center flex-column justify-center">
                            <v-icon icon="mdi-format-color-text"></v-icon>

                            <v-sheet
                                color="purple"
                                height="4"
                                tile
                                width="26"
                            ></v-sheet>
                          </div>
                        </v-btn>
                      </v-btn-toggle>

                      <v-btn-toggle
                          v-model="alignment"
                          divided
                          variant="outlined"
                      >
                        <v-btn>
                          <v-icon icon="mdi-format-align-center"></v-icon>
                        </v-btn>

                        <v-btn>
                          <v-icon icon="mdi-format-align-left"></v-icon>
                        </v-btn>

                        <v-btn>
                          <v-icon icon="mdi-format-align-right"></v-icon>
                        </v-btn>
                      </v-btn-toggle>
                    </div>

                    <v-sheet class="pa-4 text-center">
                      <v-textarea
                          v-model="note"
                          auto-grow
                          full-width
                          hide-details
                          readonly
                          rows="2"
                          variant="outlined"
                      ></v-textarea>
                    </v-sheet>
                  </v-card>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="12" md="6" >
              <v-card class="pt-2 card-hight-fixed " flat style="padding-right: 20px; padding-left: 5px;">
                <v-row class="textfield2">
                  <v-text-field
                      v-model="outletAddress"
                      label="Outlet Address"
                      readonly
                      variant="outlined"
                  ></v-text-field>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="latitude"
                          label="Latitude"
                          readonly
                          variant="outlined"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="longitude"
                          label="Longitude"
                          readonly
                          variant="outlined"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                </v-row>
                <v-divider></v-divider>
                <v-card-title >
                  <h4 >OUTLET LOCATION</h4>
                </v-card-title>
                <v-card class="mt-3" height="100%" width="100%">

                  <Map ref="map"  :center="mapCenter" :zoom="13" class="d-flex map" style="width: 100%; height: 55vh">
                    <Marker
                        v-if="markerPosition"
                        :icon="customIcon"
                        :position="markerPosition"
                    />
                    <Circle
                        v-if="markerPosition"
                        :center="markerPosition"
                        :options="circleOptions"
                        :radius="radius"
                    />
                  </Map>

                  <!--            <Map v-if="latitude && longitude" ref="map" :center="{ lat: latitude, lng: longitude }" :zoom="12" class="d-flex map" style="width: 100%; height: 50vh">-->
                  <!--              <Marker-->
                  <!--                  v-if="markerPosition"-->
                  <!--                  :icon="customIcon"-->
                  <!--                  :position="markerPosition"-->
                  <!--              />-->
                  <!--            </Map>-->

                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </div>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" max-height="500" max-width="500" persistent>
      <v-card class="pa-5">
        <v-row dense style="background-color: #d9d9d9;">
          <v-col cols="12" md="10">
            <v-card-title class="headline">DELETE OUTLET DATA</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-btn class="mt-2 mr-2" icon size="x-small" @click="deleteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-4"></v-divider>
        <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this outlet record ?</v-card-text>
        <v-card-text class="font-weight-medium">
          This outlet record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>
          <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteDialog = false">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-divider></v-divider>

    <v-dialog v-model="editDialog" class="solid-dialog" fullscreen persistent>
      <v-card>
        <v-row class="d-flex">
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-toolbar-title class="mt-1 ml-6" >
              <img :src="require('@/assets/app_bar_logo.png')"  @click="homeReload()"/>
            </v-toolbar-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="10">
            <v-btn class="closeC bg-red mr-2 justify-center font-weight-thin mt-3 mr-3" color="white" height="30" icon rounded="lg" variant="text" width="90" @click="closeD" >
              <v-icon>mdi-close-circle-outline</v-icon> CLOSE
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
        </v-row>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
// import {EventBus} from "@/plugins/event-bus";
import axios from "axios";
import {mapGetters} from "vuex";
import { Map, Marker, Circle } from '@fawmi/vue-google-maps';
import {EventBus} from "@/plugins/event-bus";

export default {
  name: "Outlet_Detail.vue",
  components:{
    Circle,
    Map,
    Marker,
  },
  data() {
    return {
      mapCenter: {},
      deleteDialog: false,
      itemToDelete: null,
      closeDialog: false,
      editDialog: false,
      fetchData:'',
      outletStates:'',
      ownerName:'',
      province:'',
      district:'',
      dsd:'',
      gnd:'',
      mobileNumber:'',
      outletName:'',
      outletAddress:'',
      outletType:'',
      latitude:'',
      longitude:'',
      note:'',

      outletProducts: [],

      circleOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      },

      markerPosition: null,
      radius: 800,
      customIcon: {
        url: require('@/assets/ICONS/maker.png'), // Path to your custom marker icon
        scaledSize: { width: 40, height: 40 } // Adjust the size if needed
      }

    };
  },

  methods: {

    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;

    },
    deleteItem() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataOutlet;

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/delete/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.deleteDialog = false;
            this.itemToDelete = null;
            EventBus.emit('outletManagementButtonClick2');
          })
          .catch(error => {
            console.error('Error deleting route:', error);
          });
    },
    exportPDF() {

    },

    closeD() {
      this.viewDialog = false;
      this.editDialog = false;
    },
    editViewOutlet() {
      //  functionality to edit item details
      this.editDialog = true;
      EventBus.emit('outletManagementButtonClick');
    },

    fetchOutletRecodeData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataOutlet;

      console.log('Shared Data:', this.sharedDataOutlet);
      console.log('fk', id);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/${id}`, { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
          .then(response => {
            console.log("API Response all:", response.data);
            if (response.data) {

              this.outletProducts = Array.isArray(response.data) ? response.data: [response.data];
              console.log("this.outletProducts:", this.outletProducts);

              if (this.outletProducts.length > 0) {
                const route = this.outletProducts[0];
                this.ownerName = route.owner_name;
                this.latitude = route?.latitude;
                this.longitude = route?.longitude;
                this.province = route?.province;
                this.district = route?.district;
                this.dsd = route?.dsd;
                this.gnd = route?.gnd;
                this.outletStates = route?.outlet_status;
                this.outletName = route?.outlet_name;
                this.outletType = route?.outlet_type.outlet_name;
                this.outletAddress = route?.outlet_address;
                this.mobileNumber = route.mobile_number;
                this.note = route?.status_reasons[0]?.reason;
                console.log("sahan", this.note);

                this.markerPosition = { lat: parseFloat(this.latitude), lng: parseFloat(this.longitude) };
                this.mapCenter = { lat: parseFloat(this.latitude), lng: parseFloat(this.longitude) };

              }
            } else {
              this.outletProducts = [];
            }
          })
          .catch(error => {
            console.log(error);
            this.outletProducts = [];
          });
    }
  },

  computed: {
    ...mapGetters(['sharedDataOutlet']),
    imageSrc() {
      return `${process.env.VUE_APP_API_BASE_URL}${this.userImage}`;
    }
  },
  created() {
    this.fetchOutletRecodeData();

    // EventBus.on('viewAgentDetails',(data) =>{
    //   console.log('sahan', data.Id);
    // });
  },

  // beforeUnmount() {
  //   EventBus.off('viewAgentDetails', fetchData =>{
  //     console.log('pppppp', fetchData);
  //   });
  // },
  mounted() {
    this.fetchOutletRecodeData();
  },

};
</script>


<style scoped>


.map::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  background: url('@/assets/ICONS/maker.png') no-repeat center center;
  background-size: cover;
  transform: translate(-50%, -50%);
}

.closeC{
  justify-content: right;
  margin-top: 0;
  margin-left: 88vw;

}
.card-hight-fixed{
  height: 100%;
  overflow: auto;
  padding-bottom: 40px;
}

.pdfC{
  display: flex;
  margin-top: 40px;
}

.editB{
  display: flex;
  margin-right: 10px;
}
.image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden; /* Ensure the image does not overflow the div */
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
}

.rounded-circle {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire div */
  display: block;
}

.textCenter {
  text-align: center;
}


.textfield{
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-left: 50px;
  margin-top: 20px;
}

.textfield2{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 20px;
}

.action {
  margin-left: 10px;
  display: flex;
  justify-content: right;
}

.first_row{
  display: flex;
  justify-content: flex-end;

}

.secondC{
  display: flex;

}


.tableCard{
  height: 31vh;
}

.red--text{
  color: red;
}

</style>
