<template>
    <v-row class="mt-1">
      <v-row style="height: auto; display: flex">
        <v-col>
      <v-card-title class="card-h4-title">OUTLET MAP
      </v-card-title>
        </v-col>
        <v-col class="card-body">
        <v-btn class="red-light-text" variant="outlined" @click="exportPDF">
            <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
        </v-col>

    </v-row>

      <v-divider class="my-3"></v-divider>
        <v-col cols="3">
          <v-select
              v-model="outlet.province"
              :items="provinces"
              :loading="provinceLoading"
              label="Province"
              placeholder="Select province"
              variant="outlined"
              @update:modelValue="getAllDistrictAccProv"
              clearable
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
              v-model="outlet.district"
              :items="districts"
              :loading="mDistrictLoading"
              label="District"
              placeholder="Select district"
              variant="outlined"
              @update:modelValue="getAllDsd"
              clearable
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
              v-model="outlet.dsd"
              :items="dsdList"
              :loading="mDSDLoading"
              label="DSD"
              placeholder="Select DSD"
              variant="outlined"
              @update:modelValue="getAllGnd"
              clearable
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
              v-model="outlet.gnd"
              :items="gndList"
              :loading="gndLoading"
              label="GND"
              placeholder="Select GND"
              variant="outlined"
              clearable
          ></v-select>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
          <Map ref="mapElement" :center="mapCenter" :zoom="12" class="d-flex map-styles">
            <Marker
                v-for="outlet in filteredOutlets"
                :key="outlet.id"
                :position="{ lat: parseFloat(outlet.latitude), lng: parseFloat(outlet.longitude) }"
                :title="outlet.outlet_name"
                :icon="{
          url: require('@/assets/ICONS/maker.png'),
          scaledSize: { width: 30, height: 25 },
        }"
            ></Marker>
          </Map>
        </v-col>
    </v-row>
</template>
    
<script>

import { Map, Marker } from '@fawmi/vue-google-maps';
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
    components: {
        Map,
        Marker,
        
    },
    name: 'OutletMapDashboardComponentFour',

  data(){

      return{

        provinceLoading:false,
        mDistrictLoading: false,
        mDSDLoading: false,
        mGNDLoading: false,
        gndLoading:false,
        provinces: [],
        districts: [],
        dsdList: [],
        gndList: [],

        outlet: {
          province: '',
          district: '',
          dsd: '',
          gnd: '',

        },

      mapCenter: { lat: 6.8738188, lng: 79.9931036 }, // Default map center
      outlets: [], 
      }
  },
    
  mounted() {
    this.getAllProvinces();
    this.getOutletLocation();
  },
  computed: {
    // filteredOutlets() {
    //   return this.outlets.filter(outlet => outlet && outlet.id && outlet.latitude && outlet.longitude);
      
    // }
    filteredOutlets() {
      console.log('Selected filters:', this.outlet);
      console.log('All outlets:', this.outlets);

      return this.outlets.filter((outlet) => {
        console.log('Filtering outlet:', outlet);
        return (
          (!this.outlet.province || outlet.province === this.outlet.province) &&
          (!this.outlet.district || outlet.district === this.outlet.district) &&
          (!this.outlet.dsd || outlet.dsd === this.outlet.dsd) &&
          (!this.outlet.gnd || outlet.gnd === this.outlet.gnd)
        );
      });
    },
    
  },
  methods: {

    exportPDF() {

      this.$nextTick(() => {
        const mapElement = this.$refs.mapElement?.$el || this.$refs.mapElement;

        if (mapElement instanceof HTMLElement) {
          html2canvas(mapElement, {
            useCORS: true, // Use CORS to allow cross-origin tiles to be captured
            logging: true,
            scale: 2 // Increase the scale to get better resolution
          }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
              orientation: 'landscape', // You can adjust this based on your map size
              unit: 'pt',
              format: [canvas.width, canvas.height] // Use the exact size of the canvas for PDF format
            });

            // Add the captured image to the PDF
            pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

            // Save the PDF
            pdf.save('outlet_map.pdf');
          }).catch((error) => {
            console.error('Error capturing the map:', error);
          });
        } else {
          console.error('Map element is not attached or is not a valid node.');
        }
      });
    },




  // Get OutLet Location--------------------------------------------------
 
    getOutletLocation() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('userId', userId); 

      axios.post('/api/getOutletLocationDetails', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.status === 200) {
          this.outlets = response.data.data;
          this.setMapCenter();
          console.log('data',this.outlets);
        } else {
          console.error("Error:", response.data.error);
        }
      })
      .catch(error => {
        console.error("Error fetching chart details:", error);
      });
    },

    setMapCenter() {
      if (this.outlets.length > 0) {
        // Center the map based on the first outlet location
        const firstOutlet = this.outlets[0];
        this.mapCenter = {
          lat: parseFloat(firstOutlet.latitude),
          lng: parseFloat(firstOutlet.longitude),
        };
      }
    },

  getAllProvinces() {
    this.districts = [];
    this.dsdList = [];
    this.gndList = [];
    this.provinceLoading = true;
    // let laravel = JSON.parse(window.Laravel);
    const header = {
      // 'X-CSRF-TOKEN': laravel.csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'multipart/form-data',
    };

    axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
        .then(response => {
          if (response.data.http_status === 'success') {
            this.provinces = response.data.data;
            this.provinceLoading = false;
          }
        })
        .catch(() => {
          this.provinceLoading = false;
        });
  },

  getAllDistrictAccProv(province) {
    console.log('Province selected:', province);
    this.districts = [];
    this.mDistrictLoading = true;
    // let laravel = JSON.parse(window.Laravel);
    const headers = {
      // 'X-CSRF-TOKEN': laravel.csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'multipart/form-data',
    };

    axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', value: province }, headers)
        .then(response => {
          console.log('District API response:', response.data);
          if (response.data.http_status === 'success') {
            this.districts = response.data.data;
            console.log('District API response:', response.data);
            this.mDistrictLoading = false;

          }
        })
        .catch(error => {
          this.mDistrictLoading = false;
          console.error('Error fetching districts:', error);

        });
  },

  getAllDsd(district) {
    this.dsdList = [];
    this.mDSDLoading = true;
    // let laravel = JSON.parse(window.Laravel);
    const header = {
      // 'X-CSRF-TOKEN': laravel.csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'multipart/form-data',
    };

    axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', value: district }, header)
        .then(response => {
          if (response.data.http_status === 'success') {
            this.dsdList = response.data.data;
            this.mDSDLoading = false;
          }
        })
        .catch(() => {
          this.mDSDLoading = false;
        });
  },

  getAllGnd(dsd) {
    this.gndList = [];
    this.gndLoading = true;
    // let laravel = JSON.parse(window.Laravel);
    const header = {
      // 'X-CSRF-TOKEN': laravel.csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'multipart/form-data',
    };

    axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', value: dsd }, header)
        .then(response => {
          if (response.data.http_status === 'success') {
            this.gndList = response.data.data;
            this.gndLoading = false;
          }
        })
        .catch(() => {
          this.gndLoading = false;
        });
  },

 
},
  watch: {
    'outlet.province'(newValue) {
      this.getAllDistrictAccProv(newValue);
    },
    'outlet.district'(newValue) {
      this.getAllDsd(newValue);
    },
    'outlet.dsd'(newValue) {
      this.getAllGnd(newValue);
    }
  },
  
};
</script>
    
<style scoped>
.v-card-title {
    font-weight: bold;
}
.map-styles {
  width: 100%;
  height: 400px;
  position: relative;

}

.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
  font-size: 1rem;
}


.red-light-text {
  background-color: #f1cccc;
  color: #b00d0d;

}
.card-body{
  display: flex;
  justify-content: end;
  justify-items: end;
  margin-right: 5%;
}


.red-text {
  color: #b00d0d;
}

.gray-text {
  color: #757575 !important;
}
</style>
    