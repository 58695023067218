<template>
  <v-container fluid>
  <v-row class="main-heading">
    <v-col cols="12" md="11">
      <v-row>
        <v-card-title>TRACKING HISTORY</v-card-title>
      </v-row>
      <v-row>
        <v-card-subtitle>Analyse the collection of history tracking data based on agents.</v-card-subtitle>
      </v-row>
    </v-col>
    <v-col cols="12" md="1">
      <v-btn class="back-button">BACK</v-btn>
    </v-col>
  </v-row>

  <v-row class="sub-heading">
    <v-card class="sub-heading-card d-flex align-center" variant="flat">
      <v-col cols="12" md="10">
        <v-card-subtitle class="his-sub-heading">HISTORY</v-card-subtitle>
      </v-col>
      <v-btn class="mr-5 bg-red-lighten-4" icon rounded="lg" size="small" width="80" @click="exportPDF">
        <v-icon class="bg-red">mdi-file-pdf</v-icon> PDF
      </v-btn>
      <v-btn class="ml-1 mr-12 bg-green-lighten-4" icon rounded="lg" size="small"  width="80" @click="exportExcel">
        <v-icon class="bg-green">mdi-file-excel</v-icon> EXEL
      </v-btn>
    </v-card>
  </v-row>

  <v-card class="main-card ">
    <v-row class="text-filed-row">
      <v-col cols="12" md="4">
        <v-text-field
            class="text-black"
            label="Date"
            persistent-placeholder
            placeholder="Select Date Range"
            readonly
            variant="outlined"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="8">
        <v-text-field
            class="text-black"
            label="Search"
            persistent-placeholder
            placeholder="Search"
            readonly
            variant="outlined"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="table-row">
      <v-col class="table-col" cols="12">
        <v-data-table
            :headers="headers"
            :items="sales"
            :search="search"
            class="data-table"

        >
          <template v-slot:[`item.action`]="{ item }" >
            <v-row>
              <v-col  cols="5">
                <v-btn
                    class="mr-2 bg-blue-lighten-4"
                    color="blue"
                    icon
                    rounded="lg"
                    size="small"
                    variant="outlined"
                    width="210"
                    @click="mapView()"
                >
                  <v-icon class="mr-2">mdi-check-circle</v-icon>MAP VIEW
                </v-btn>
              </v-col>
              <v-col class=" ml-0" cols="4">
                <v-btn
                    class="bg-red-lighten-4"
                    color="red"
                    icon
                    rounded
                    size="small"
                    variant="outlined"
                    @click="confirmDelete(item)"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>

        <!-- Delete Confirmation Dialog -->
        <v-dialog v-model="deleteDialog" max-height="500" max-width="500" persistent>
          <v-card class="pa-1">
            <v-row>
              <v-col cols="12" md="10">
                <v-card-title class="headline">DELETE COLLECTION DATA</v-card-title>
              </v-col>
              <v-col class="d-flex justify-end" cols="12" md="2">
                <v-btn class="mt-3" size="x-small" @click="deleteDialog = false">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this collection record ?</v-card-text>
            <v-card-text class="font-weight-medium">
              This collection record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>
              <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteDialog = false">CANCEL</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-col>
    </v-row>

    <!--  collection recode edit view component call to dialog box-->
    <v-dialog v-model="mapViewDialog" class="solid-dialog" fullscreen persistent>
      <v-card>
        <v-row class="first-row-dialog" >
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-toolbar-title class="mt-1 ml-6" >
              <img  :src="require('@/assets/app_bar_logo.png')" alt=""/>
            </v-toolbar-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="10">
            <v-btn class="closeC bg-red mr-2 justify-center font-weight-thin mt-3 mr-3" color="white" height="30" icon rounded="lg" variant="text" width="90" @click="closeD" >
              <v-icon>mdi-close-circle-outline</v-icon> CLOSE
            </v-btn>
          </v-col>
          <v-row  >
            <v-col class="fc" cols="12" md="4" >
              <v-icon>mdi-view-dashboard
              </v-icon>
              <v-label class="ml-5 ">MAP VIEW</v-label>
            </v-col >
          </v-row>
        </v-row>



          <v-row class="d-flex">
            <v-col class="ml-lg-16 " cols="12" md="8" >
              <v-card v-for="agent in agents" :key="agent.id" class="mt-10 d-flex " style="height: 35vh; width: 600px" >
                <v-row>
                  <v-col class="image" cols="12" md="3">
                    <v-img
                        :src="agent.image"
                        class="rounded-circle ml-6 mt-10"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-card class="dataCard" style="height: 34vh; width: 450px" variant="flat">
                      <v-card-text>
                        <div class="font-weight-bold ms-1 mb-2">Agent name  : {{agent.name}}</div>
                        <v-timeline align="start" density="compact">
                          <v-timeline-item
                              v-for="agent in agents"
                              :key="agent.id"
                              dot-color="red"
                              size="x-small"
                          >
                              <v-row v-for="agent in agents" :key="agent.id">
                              <v-col cols="12" md="1">
                                <v-icon  color="red">mdi-check-circle-outline</v-icon>
                              </v-col>
                              <v-col cols="12" md="10">
                                <v-row v-for="trip in agent.trips" :key="trip.id">
                                  <v-card-title class="mt=0 " style="font-size: 12px">{{trip.destination}}</v-card-title>
                                  <v-card-subtitle class="mt-0" style="font-size: 12px">{{agent.locAddress}}</v-card-subtitle>
                                </v-row>
                                <v-row v-for="trip in agent.trips" :key="trip.id" >
                                  <v-card-text>{{trip.time}}</v-card-text>
                                  <v-chip v-if= "agent.locationType === 'startLocation' " class="mt-3" color="blue" size="x-small" variant="flat">
                                    Start Location
                                  </v-chip>
                                  <v-chip v-if= "agent.locationType === 'endLocation' " class="mt-4" color="green" size="x-small" variant="flat">
                                    End Location
                                  </v-chip>
                                </v-row>
                                <v-divider></v-divider>
                              </v-col>
                              </v-row>

                          </v-timeline-item>
                        </v-timeline>
                      </v-card-text>
                    </v-card>

                  </v-col>
                </v-row>

              </v-card>
            </v-col>
            <v-col cols="12" md="3" >
              <v-btn class="mr-5 bg-red-lighten-4" icon rounded="lg" size="small" style="margin-top: 30vh; margin-left: 10vw" width="200" @click="exportPDF">
                <v-icon class="bg-red">mdi-file-pdf</v-icon>Download PDF
              </v-btn>
            </v-col>
          </v-row>


        <v-row >
          <!--history map view-->
            <v-card class="pa-5" width="100%" >
              <Map :center="mapCenter" :zoom="12" class="d-flex map" style="width: 100%; height: 50vh;">
              </Map>
            </v-card>
.
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
  </v-container>
</template>

<script>

import {ref} from "vue";
import { Map } from '@fawmi/vue-google-maps';
import index from "vuex";


export default {
  computed: {
    index() {
      return index
    }
  },
  components: {
    Map,
  },
  data() {
    return {
      search: ' ',
      deleteDialog: false,
      itemToDelete: null,
      mapViewDialog: false,
      headers: [
        {text: 'Store Name', value: 'store', title: 'STORE NAME',},
        {text: 'Address', value: 'address', title: 'ADDRESS'},
        {text: 'Agent Name', value: 'agent', title: 'AGENT NAME'},
        {text: 'Quantity Sold', value: 'quantity', title: 'QUANTITY SOLD'},
        {text: 'Total Sales', value: 'total', title: 'TOTAL SALES'},
        {text: 'Actions', value: 'action', sortable: false, title: 'ACTION'},
      ],
      sales: [
        {
          store: 'Gamage Stores',
          address: 'No:22/1, Kumarakanda Road, Gonapinuwala',
          agent: 'Mr. Nehan Pannaseeha',
          reg: '1034',
          quantity: 'QUA-02',
          total: 'LKR. 106,995.00'
        },
        {
          store: 'Liyanage Enterprises',
          address: 'No : 12/3, Daisyvilla Avenue, Piliyandala',
          agent: 'Mr. Cherith Maitreya',
          reg: '1351',
          quantity: 'QUA-04',
          total: 'LKR. 427,000.00'
        },
        {
          store: 'Naveen Groceries',
          address: '264/1, Sri Jayawardenepura Kotte 10027',
          agent: 'Mr. Supun Kanakeratne',
          reg: '2024',
          quantity: 'QUA-03',
          total: 'LKR. 520,100.00'
        },
        {
          store: 'Jayasundara Stores',
          address: 'No: 23/4 Bandaranaike Mawatha, Nuggeda, 8090',
          agent: 'Mr. Kumarasri Aponsu',
          reg: '0784',
          quantity: 'QUA-05',
          total: 'LKR. 335,000.00'
        },
        {
          store: 'A.K.A. Wadood & Sons',
          address: '693/3A, Peradeniya Road, Mulgampola',
          agent: 'Mr. Mahendra Perera',
          reg: '5672',
          quantity: 'QUA-04',
          total: 'LKR. 165,200.00'
        },
      ],
    };
  },
  methods: {
    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    deleteItem() {
      // Implement your delete item logic here
      this.sales = this.sales.filter(sale => sale !== this.itemToDelete);
      this.deleteDialog = false;
      this.itemToDelete = null;
    },
    mapView() {
      this.mapViewDialog = true;
    },
    closeD(){
      this.mapViewDialog = false;
    }
  },
  setup() {
    const agents = ref([
      {
        id: 1,
        image: 'https://picsum.photos/200',
        name: 'Mr. Nehan Pannaseeha',
        regNum: '1001',
        vehicle: 'BHK-4321',
        avatar: 'path/to/avatar.jpg',
        trackingEnabled: true,
        position: {lat: 7.8731, lng: 80.7718},
        location: 'Mawaramandiya',
        lastUpdated: '30 seconds ago',
        locAddress: 'bla blaa blaaaaa',
        locationType: 'startLocation',
        trips: [
          {id: 1, destination: 'Keells - Edmonton', time: '09:16 AM'},
          {id: 2, destination: 'Keells - Kohuwala', time: '10:30 AM'},
        ],
      },
    ]);

    const mapCenter = ref({lat: 7.8731, lng: 80.7718});

    return {
      agents,
      mapCenter,

    }
  },
}
</script>

<style scoped>

.main-heading {
  height: 10vh;
  padding: 15px;
  margin: 0;
  display: flex;
}

.back-button {
  margin-right: 30px;
  padding: 5px;
}

.sub-heading {
  height: 10vh;
  padding: 10px;
  margin: 0;
}
.map {
  width: 100%;
  height: 100%;

}

.sub-heading-card {
  width: 100vw;
  height: 100%;
  padding: 10px;
}

.main-card{
  height: 75vh;
  margin: 10px;
}
.text-filed-row{
  height:13vh;
  padding: 20px;
}
.table-row{
  height: 70vh;
}
.table-col{
  height: 95%;
}
.data-table{
  height: 90%;
  border-radius: 10px;
  border: 1px solid rgb(227, 229, 231);
  margin: 20px;
  display: flex;
  justify-content: center;
  justify-items: center;

}
.fc{
  margin-left: 40px;
}
.first-row-dialog{
  height: 5vh;
}
:deep(.data-table) th , td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
}

:deep(.data-table) td, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
}

:deep(.data-table) {
  border: white;
}

:deep(.data-table )  tr,  th:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}
:deep(.data-table )  tr,  td:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}


</style>



