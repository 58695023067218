<template>
  <v-container fluid style="padding: 5px">
  <v-row class="main-heading">
    <v-col cols="12" md="11">
      <v-row>
        <v-card-title>PARKING AGENTS LIST</v-card-title>
      </v-row>
      <v-row>
        <v-card-subtitle>Analyse parking agents data.</v-card-subtitle>
      </v-row>
    </v-col>
    <v-col cols="12" md="1">
      <v-btn class="back-button" @click="back()">BACK</v-btn>
    </v-col>
  </v-row>

  <v-row class="sub-heading">
    <v-card class="sub-heading-card d-flex align-center">
      <v-col cols="12" md="10">
        <v-card-subtitle class="his-sub-heading">SELLERS LIST</v-card-subtitle>
      </v-col>
      <v-col cols="12" md="1" class="col1">
        <v-btn class="pdf-btn">PDF</v-btn>
      </v-col>
      <v-col cols="12" md="1" class="col2">
        <v-btn class="exl-btn">Excel</v-btn>
      </v-col>
    </v-card>
  </v-row>

  <v-card class="main-card ">
    <v-row class="text-filed-row">
      <v-col cols="12" md="12">
        <v-text-field
            class="text-black"
            label="Search"
            placeholder="Search"
            persistent-placeholder
            variant="outlined"
            readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="table-row">
      <v-col cols="12" class="table-col">
        <v-data-table
            :headers="headers"
            :items="sales"
            :search="search"
            class="dataTable"
        >
          <template v-slot:[`item.action`]="{ item }" >
            <v-row >
              <v-switch
                  color="red"
                  label="Enable"
                  value="red"
                  hide-details
                  class="BTNswitch"
                  v-model="item.trackingEnabled"
                  @change="toggleEnable(item)"
              ></v-switch>
            </v-row>
            <v-row>
              <v-card-text class="action-text">ENABLE | Disable Tracking Permission </v-card-text>
            </v-row>
          </template>

        </v-data-table>

      </v-col>
    </v-row>
  </v-card>
    </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      headers: [
        { text: 'Store Name', value: 'store', title: 'AGENT NAME' },
        { text: 'Address', value: 'address', title:'CURRENT ADDRESS'},
        { text: 'Agent Name', value: 'agent', title:'DISTRICT' },
        { text: 'Quantity Sold', value: 'quantity', title:'DSD' },
        { text: 'Total Collections', value: 'total', title: 'VE.NUMBER' },
        { text: 'Total Collections', value: 'states', title: 'STATES' },
        { text: 'Actions', value: 'action', sortable: false , title:'ACTION' },
      ],
      sales: [
        { store: 'Gamage Stores', address: 'No:22/1, Kumarakanda Road, Gonapinuwala', agent: 'Mr. Nehan Pannaseeha', reg: '1034', quantity: 'QUA-02', total: 'LKR. 106,995.00', states:'ontrip' },
        { store: 'Liyanage Enterprises', address: 'No : 12/3, Daisyvilla Avenue, Piliyandala', agent: 'Mr. Cherith Maitreya', reg: '1351', quantity: 'QUA-04', total: 'LKR. 427,000.00', states:'parking'},
        { store: 'Naveen Groceries', address: '264/1, Sri Jayawardenepura Kotte 10027', agent: 'Mr. Supun Kanakeratne', reg: '2024', quantity: 'QUA-03', total: 'LKR. 520,100.00', states:'ontrip'},
        { store: 'Jayasundara Stores', address: 'No: 23/4 Bandaranaike Mawatha, Nuggeda, 8090', agent: 'Mr. Kumarasri Aponsu', reg: '0784', quantity: 'QUA-05', total: 'LKR. 335,000.00',states:'idling' },
        { store: 'A.K.A. Wadood & Sons', address: '693/3A, Peradeniya Road, Mulgampola', agent: 'Mr. Mahendra Perera', reg: '5672', quantity: 'QUA-04', total: 'LKR. 165,200.00',states:'ontrip' },
      ],
    }
  },
  setup(){
    const toggleEnable = (item) => {
      item.trackingEnabled = !item.trackingEnabled;
    };

    return {
      toggleEnable,
    };
  },
  methods: {
    back(){
      this.$router.push('/live-tracking');
    }
  },
};
</script>

<style scoped>

.main-heading {
  height: 10vh;
  padding: 10px;
  margin: 0;
  display: flex;
}

.back-button {
  margin-right: 30px;
  padding: 5px;
}

.sub-heading {
  height: 10vh;
  padding: 10px;
  margin: 0;
}

.sub-heading-card {
  width: 100vw;
  height: 100%;
  padding: 10px;
}

.pdf-btn{
  width:60px;
  background-color: rgb(233, 196, 204);
  border: 1px solid red;
  margin-left: 30px;
}

.exl-btn{
  width:60px;
  background-color: rgb(82, 180, 109);
  border: 1px solid rgb(16, 69, 13);
}

.text-filed-row{
  height:13vh;
  padding: 20px;
}
.table-col{
  height: 95%;
}

.action-text{
  font-size: 9px;
}
.data-table{
  height: 90%;
  border-radius: 10px;
  border: 1px solid rgb(227, 229, 231);
  margin: 20px;
}
.view-btn{
  background-color: rgb(140, 182, 249);
  border: 1px solid rgb(76, 76, 228);
  border-radius: 5px;
  color: rgb(76, 76, 228);
  margin-right: 20px;
}
.del-btn{
  background-color: rgb(233, 196, 204);
  border: 1px solid red;
  border-radius: 5px;
  color: red;
  margin-right: 20px;
}



</style>



