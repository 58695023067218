import { createStore } from 'vuex';

export default createStore({
    state() {
        return {
            sharedData: null,
            sharedDataRoutes: null,
            sharedDataCollection: null,
            sharedDataOutlet: null,
            sharedDataOrganization: null,
            checkListDateils:[],
            dashboard: {
                notVisitedCount: 0,
                visitedCount: 0,
                totalOfficer: 0,
              },
              dataToSave:'',

        };
    },
    mutations: {
        setSharedData(state, data) {
            console.log('Setting shared data:', data);
            state.sharedData = data;
        },
        setSharedDataRoutes(state, data) {
            console.log('Setting shared data:', data);
            state.sharedDataRoutes = data;
        },
        setSharedDataCollection(state, data) {
            console.log('Setting shared data:', data);
            state.sharedDataCollection = data;
        },
        setSharedDataOutlet(state, data) {
            console.log('Setting shared data:', data);
            state.sharedDataOutlet = data;
        },
        setSharedDataOrganization(state, data) {
            console.log('Setting shared data organization:', data);
            state.sharedDataOrganization = {
                ...state.sharedDataOrganization,
                ...data,
            }
        },

        setDashboardDetails(state, payload) {
            state.dashboard = payload;
        },
        setDataToSave(state, data) {
            state.checkListDateils = data;
          }
    },
    actions: {
        updateSharedData({ commit }, data) {
            console.log('Updating shared data:', data)
            commit('setSharedData', data);
        },
        updateSharedDataRoutes({ commit }, data) {
            console.log('Updating shared data:', data)
            commit('setSharedDataRoutes', data);
        },
        updateSharedDataCollection({ commit }, data) {
            console.log('Updating shared data:', data)
            commit('setSharedDataCollection', data);
        },
        updateSharedDataOutlet({ commit }, data) {
            console.log('Updating shared data:', data)
            commit('setSharedDataOutlet', data);
        },
        updateSharedDataOrganization({ commit }, data) {
            console.log('Updating shared data organization:', data);
            commit('setSharedDataOrganization', data);
        },
        updateDashboardDetails({ commit }, dashboardData) {
            commit('setDashboardDetails', dashboardData);
        },
        saveDataChecklistData({ commit }, dataToSave) {
            commit('setDataToSave', dataToSave);
          }

    },
    getters: {
        sharedData: (state) => state.sharedData,
        sharedDataRoutes: (state) => state.sharedDataRoutes,
        sharedDataCollection: (state) => state.sharedDataCollection,
        sharedDataOutlet: (state) => state.sharedDataOutlet,
        sharedDataOrganization: (state) => state.sharedDataOrganization,
        getDashboardDetails: (state) => state.dashboard,
        getCheckListDetails: (state) => state.checkListDateils
        
    },
});