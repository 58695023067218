<template >
  <v-card class="main-card" >
    <v-row class="px-6">

      <v-col class="mt-3" cols="6">
        <h3 class="page-title">OUTLET LISTS</h3>
        <h5 class="light-gray-text">Analyse the created outlet list using a variety of parameters.</h5>
        <v-card-title>

        </v-card-title>
      </v-col>

      <v-col class="px-4 mt-5" cols="6">
        <v-row class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
          <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>

    </v-row>

    <v-card height="100%" style=" padding-bottom: 20px; margin-left: 40px; overflow-y: auto;" >
      <h5 class="card-h4-title px-3 mt-2 mb-4 ml-4">OUTLET DATA ANALYSIS
        <div class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-btn class="bg-red-lighten-4 text-red mr-3" variant="outlined" @click="exportPDF">
            <v-icon class="text-red">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>

          <v-btn class="bg-green-lighten-4 text-green" variant="outlined" @click="exportExcel">
            <v-icon class="text-green">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </div>
      </h5>

      <v-divider class="pb-6 w-100"></v-divider>

      <v-data-table
          :headers="headers"
          :items="filteredRoutes"
          :items-per-page="5"
          :search="search"
          class="data-table"
          style="padding-left: 25px; padding-right: 10px;  padding-bottom: 30px; height: fit-content; margin-bottom: 20px "
      >
        <template v-slot:[`item.outlet_status`]="{ item }">
          <div style="padding: 10px">

            <div style="padding-bottom: 3px">
              <v-chip v-if="item.outlet_status === 'ALLOWED'" color="green" label style="height: 20px; width:140px; font-size: 12px; background: palegreen;" >
                ALLOWED
                <v-icon class="justify-end ml-12" >mdi-check</v-icon>
              </v-chip>

              <v-chip v-if="item.outlet_status === 'NOT ALLOWED'" class="bg-red-lighten-4" color="red" label style="height: 20px; width:140px; font-size: 12px;  " >
                NOT ALLOWED
                <v-icon class="justify-end ml-6" >mdi-close</v-icon>
              </v-chip>

              <v-chip v-if="item.outlet_status === 'NEED APPROVAL'" color="orange" label style="height: 20px; width:140px; font-size: 12px; background: lightgoldenrodyellow "  >
                NEED APPROVAL
                <v-icon class="justify-end ml-4" >mdi-help-circle-outline</v-icon>
              </v-chip>
            </div>


            <div >
              <v-btn style="height: 30px; width:140px; font-size: 9px; background: darkgrey" @click="updateStates(item)" >UPDATE STATES</v-btn>
            </div>
          </div>
        </template>



        <template v-slot:top>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                  v-model="selectedOutlet"
                  :items="outletTypes"
                  chips
                  item-title="outlet_name"
                  item-value="id"
                  label="Select Outlet"
                  multiple
                  variant="outlined"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                  v-model="selectedOutletStatus"
                  :items="outletSates"
                  chips
                  item-title="outlet_status"
                  item-value="outlet_status"
                  label="Outlet Status"
                  multiple
                  variant="outlined"
              ></v-select>
            </v-col>

          </v-row>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-row >
            <v-col cols="12" md="5">
              <v-btn
                  class="mr-1 bg-blue-lighten-5 pa-2 "
                  color="blue"
                  icon
                  rounded="lg"
                  size="small"
                  variant="outlined"
                  width="120"
                  @click="viewItem(item)"
              >
                <v-icon>mdi-check-circle</v-icon> view
              </v-btn>
            </v-col>
            <v-col class="mr-0 d-flex justify-end" cols="12" md="3">
              <v-btn
                  class="editB bg-green-lighten-5 pa-2"
                  color="green"
                  icon
                  rounded
                  size="small"
                  width="90"
                  variant="outlined"
                  @click="editItem(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
            <v-col class="ml-0" cols="12" md="3">
              <v-btn
                  class="bg-red-lighten-5 pa-2"
                  color="red"
                  icon
                  rounded
                  size="small"
                  width="90"
                  variant="outlined"
                  @click="confirmDelete(item)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <!--        update states dialog-->

    <v-dialog v-model="updateDialog"  class="solid-dialog d-flex" height="80%" width="35%" >
      <v-card class="pa-6 " style="overflow-y: auto">
        <v-row dense style="background-color: #d9d9d9;">
          <v-col cols="12" md="10">
            <v-card-title class="headline" style="font-size: 17px">UPDATE STATUS</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-btn class="mt-2 mr-2" icon size="x-small" @click="updateDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-4 mb-4" ></v-divider>
        <v-row style="padding: 10px">
          <v-col >
            <v-text-field
                v-model="updateDetails.outlet_name"
                class="reminder"
                label="Outlet Name"
                readonly
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="updateDetails.owner_name"
                class="reminder"
                label="Owner Name"
                readonly
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="updateDetails.mobile_number"
                class="reminder"
                label="Mobile Number"
                readonly
                variant="outlined"
            ></v-text-field>
            <v-select
                v-model="selectOS"
                :items="outletSates"
                class="reminder"
                item-title="outlet_status"
                item-value="outlet_status"
                label="Outlet Status"
                variant="outlined"
            ></v-select>
            <v-text-field
                v-model="reason"
                class="reminder"
                label="Reason"
                variant="outlined"
            ></v-text-field>

          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row >
          <v-col class="d-flex justify-end " cols="12" md="12" @click="updateDialog = false">
            <v-btn class="mt-3 bg-red-lighten-4" color="red"  size="large" variant="outlined" @click="reminderSend(updateDetails.id)">
              UPDATE
            </v-btn>
            <v-btn class="mt-3 ml-3 bg-grey-darken-3" color="gray" size="large"  variant="outlined" @click="updateDialog = false">
              CANCEL
            </v-btn>
          </v-col>
        </v-row>

      </v-card>

    </v-dialog>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" max-height="500" max-width="500" persistent>
      <v-card class="pa-5">
        <v-row dense style="background-color: #d9d9d9;">
          <v-col cols="12" md="10">
            <v-card-title class="headline">DELETE OUTLET DATA</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-btn class="mt-2 mr-2" icon size="x-small" @click="deleteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-4"></v-divider>
        <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this outlet record ?</v-card-text>
        <v-card-text class="font-weight-medium">
          This outlet record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>
          <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteDialog = false">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="view_Dialog" class="solid-dialog" fullscreen persistent >
      <v-card class="card-size">
        <v-toolbar class="pr-5 mb-5" color="white" dense>
          <v-toolbar-title>
            <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" dark variant="elevated" @click="closeD">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row>
          <OutletDetails/>
        </v-row>
      </v-card>
    </v-dialog >


    <v-dialog v-model="editDialog" class="solid-dialog" fullscreen persistent >
      <v-card class="card-size">
        <v-toolbar class="pr-5 mb-5" color="white" dense>
          <v-toolbar-title>
            <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" dark variant="elevated" @click="closeD">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row>
          <OutletDetailsEdit/>
        </v-row>
      </v-card>
    </v-dialog >

  </v-card>
  <v-snackbar
      v-model="snackbar"
      color="success"
      location="top-right"
      outlined
      timeout="3000"
  >
    UPDATE SUCCESSFULLY.
    <template v-slot:action="{ attrs }">
      <v-btn
          icon
          v-bind="attrs"
          @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>

  <v-overlay v-model="loading" >
    <v-progress-circular class="centered-progress" indeterminate size="64"></v-progress-circular>
  </v-overlay>

</template>

<script>
import axios from 'axios';
import { EventBus } from '@/plugins/event-bus';
import OutletDetails from "@/components/OutletManagement/OutletDetails.vue";
import OutletDetailsEdit from "@/components/OutletManagement/OutletDetailsEdit.vue";
import {mapActions} from "vuex";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

export default {
  name: "OutletList",
  components: {
    OutletDetails,
    OutletDetailsEdit
  },
  data() {
    return {
      loading: false,
      snackbar: false,
      outletName: '',
      ownerName: '',
      mobileNumber: '',
      reason: '',
      search: '',
      deleteDialog: false,
      updateDialog: false,
      itemToDelete: null,
      view_Dialog: false,
      editDialog: false,
      showSwitches: false,
      SalesSwitch: false,
      CollectionsSwitch: false,
      selectOS: '',
      selectedOutlet: [],
      outletTypes: [],
      selectedOutletStatus: [],
      outletSates: ['ALLOWED', 'NOT ALLOWED', 'NEED APPROVAL'],
      headers: [
        {text: 'OUTLET TYPE', value: 'outlet_type.outlet_name', title: 'OUTLET TYPE'},
        {text: 'OUTLET NAME', value: 'outlet_name', title: 'OUTLET NAME'},
        {text: 'OUTLET STATUS', value: 'outlet_status', title: 'OUTLET STATUS'},
        {text: 'OUTLET ADDRESS', value: 'outlet_address', title: 'OUTLET ADDRESS'},
        {text: 'Actions', value: 'action', sortable: false, title: 'ACTION'},
      ],
      headersSecond: [
        {text: '#', value: 'index', title: '#'},
        {text: 'OUTLET TYPE', value: 'outlet_type.outlet_name', title: 'OUTLET TYPE'},
        {text: 'OUTLET NAME', value: 'outlet_name', title: 'OUTLET NAME'},
        {text: 'OUTLET STATUS', value: 'outlet_status', title: 'OUTLET STATUS'},
        {text: 'OUTLET ADDRESS', value: 'outlet_address', title: 'OUTLET ADDRESS'},
        {text: 'OWNER NAME', value: 'owner_name', title: 'OWNER NAME'},
        {text: 'MOBILE NUMBER', value: 'mobile_number', title: 'MOBILE NUMBER'},
        {text: 'PROVINCE', value: 'province', title: 'PROVINCE'},
        {text: 'DISTRICT', value: 'district', title: 'DISTRICT'},
        {text: 'DSD', value: 'dsd', title: 'DSD'},
        {text: 'GND', value: 'gnd', title: 'GND'},
        {text: 'LATITUDE', value: 'latitude', title: 'LATITUDE'},
        {text: 'LONGITUDE', value: 'longitude', title: 'LONGITUDE'},
      ],
      routes: [], // Initially empty, will be filled by API call
      updateDetails:[],
      id:'',
    };
  },
  methods: {
    ...mapActions(['updateSharedDataOutlet']),

    updateStates(item) {
      this.selectOS = item.outlet_status
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = item.id;

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.updateDetails = response.data;
            console.log("API Response:", response.data);
            this.updateDialog=true;
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });
    },

    fetchRoutes() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/view`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.routes = response.data;
            console.log("API Response:", response.data);
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });
    },

    fetchOutletType() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.outletTypes = response.data;
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });

    },
    // fetchOutletStates() {
    //   const token = localStorage.getItem('token');
    //   const userId = localStorage.getItem('userId');
    //
    //   axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/status/view`, {userId}, {
    //     headers: {
    //       'Authorization': `Bearer ${token}`,
    //       'Content-Type': 'application/json'
    //     }
    //   })
    //       .then(response => {
    //         this.outletSates = response.data;
    //       })
    //       .catch(error => {
    //         console.error('Error fetching routes:', error);
    //       });
    //
    // },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    deleteItem() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/delete/${this.itemToDelete.id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

          .then(() => {
            this.routes = this.routes.filter(route => route.id !== this.itemToDelete.id);
            this.deleteDialog = false;
            this.itemToDelete = null;
          })
          .catch(error => {
            console.error('Error deleting route:', error);
          });
    },
    exportPDF() {
      const doc = new jsPDF('landscape');

      // Set the title for the document
      doc.text('Outlet list', 14, 10);

      // Prepare the data for the table
      const tableData = this.routes.map((item, index) => [
        index + 1,
        item.outlet_type.outlet_name,
        item.outlet_name,
        item.outlet_status,
        item.outlet_address,
        item.owner_name,
        item.mobile_number,
        item.province,
        item.district,
        item.dsd,
        item.gnd,
        item.latitude,
        item.longitude
      ]);

      // Define the columns for the table
      const tableColumns = this.headersSecond.map(header => header.text);

      const columsWidth = {
        0: { cellWidth: 10 },
        1: { cellWidth: 25 },
        2: { cellWidth: 30 },
        3: { cellWidth: 30 },
        4: { cellWidth: 40 },
        5: { cellWidth: 20 },
        6: { cellWidth: 20 },
        7: { cellWidth: 20 },
        8: { cellWidth: 15 },
        9: { cellWidth: 15 },
        10: { cellWidth: 15 },
        11: { cellWidth: 20 },
        12: { cellWidth: 20 }
      }

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
        columnStyles: columsWidth,
        styles: {
          fontSize: 8,
          cellPadding: 2,
          overflow: 'linebreak',
          fontColor: 'black',
        },
        margin: { top: 20 },
        theme: 'grid',
        tableWidth: 'wrap',
        // didDrawCell: (data) => {
        //
        // }
      });

      // Save the PDF with a name
      doc.save('outlet_details.pdf');

    },
    exportExcel() {

      const tableData = this.routes.map((item, index) => ({
        '#': index + 1,
        'Outlet Type': item.outlet_type.outlet_name,
        'Outlet Name': item.outlet_name,
        'Outlet Status': item.outlet_status,
        'Outlet Address': item.outlet_address
      }));

      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Outlet Details');


      XLSX.writeFile(workbook, 'outlet_details.xlsx');

    },
    viewItem(item) {
      this.id = item.id
      this.updateSharedDataOutlet(item.id);
      this.view_Dialog = true;

    },
    editItem(item) {
      // Functionality to edit item details
      this.updateSharedDataOutlet(item.id);
      this.editDialog = true;
    },
    closeD() {
      this.view_Dialog = false;
      this.editDialog = false;
    },
    outletManagementButtonClick() {

      this.editDialog = true;
      this.view_Dialog = false;
    },
    backPageRoute(){
      this.$router.push('/dashboard');
    },
    o2(){
      this.editDialog = false;
      this.view_Dialog = false;
      this.routes = this.routes.filter(route => route.id !== this.id);
      this.id = null;
    },
    setupEventListeners() {
      EventBus.on('outletManagementButtonClick', this.outletManagementButtonClick);
      EventBus.on('outletManagementButtonClick2', this.o2);
    },
    removeEventListeners() {
      EventBus.off('outletManagementButtonClick', this.outletManagementButtonClick);
      EventBus.off('outletManagementButtonClick2', this.o2);
    },
    toggleSwitches() {
      this.showSwitches = !this.showSwitches;
    },
    reminderSend(id) {
      this.loading = true;

      console.log('xxxxxx', id)

      const userId = localStorage.getItem('userId');
      const outletData = {
        status: this.selectOS,
        reason: this.reason,
        userId: userId,
      };

      const token = localStorage.getItem('token');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/${id}/status`, outletData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            console.log('reminder send successfully:', response.data);
            this.fetchRoutes();
            this.snackbar = true;

          })
          .catch(error => {
            console.error('Error adding reminder:', error);

          })
          .finally (() => {
            this.loading = false;
          });
    },
  },
  mounted() {
    this.setupEventListeners();
    this.fetchRoutes();
    this.fetchOutletType();

  },
  beforeUnmount() {
    this.removeEventListeners();
  },
  computed: {
    filteredRoutes() {
      return this.routes.filter((route) => {
        const withinDateRange =
            !this.dateRange ||
            (new Date(route.assignedDate) >= new Date(this.dates[0]) &&
                new Date(route.assignedDate) <= new Date(this.dates[1]));

        const matchesCategory =
            !this.selectedOutlet.length ||
            this.selectedOutlet.includes(route.outlet_type_id);

        const matchesRouteStatus =
            !this.selectedOutletStatus.length ||
            this.selectedOutletStatus.includes(route.outlet_status);

        return withinDateRange && matchesCategory && matchesRouteStatus;
      });
    },

  }
}
</script>

<style scoped >


.main-card{
  width:100%;
  height: 100%;
  padding-right: 20px;
}


:deep(.data-table) th, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  text-align: center;

}

:deep(.data-table) td, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  max-width: 9vw;
}

:deep(.data-table) {
  border: white;

}

:deep(.data-table )  tr,  th:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
  max-width: 12vw;

}
:deep(.data-table )  tr,  td:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
  max-width: 12vw;
}


.reminder{
  color: red;
}

.v-dialog > .v-overlay__content > .v-card, .v-dialog > .v-overlay__content > .v-sheet, .v-dialog > .v-overlay__content > form > .v-card, .v-dialog > .v-overlay__content > form > .v-sheet {
  overflow-y: hidden;
}

.centered-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


</style>