
<template>
  <v-card class="main-card" >
    <v-row class="px-6">

      <v-col class="mt-3" cols="6">
        <h3 class="page-title">CREATE CHECKLIST</h3>
        <h5 class="light-gray-text">Create checklist items according to your requirements.</h5>
        <v-card-title>

        </v-card-title>
      </v-col>

      <v-col class="px-4 mt-5" cols="6">
        <v-row class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
          <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>

    </v-row>

    <v-card style="width: 100%; height: 100%" class="px-2 pt-3  mb-3 pb-2 mx-1 leads-details-card" >
      <v-row>
        <h5 class="card-h4-title px-3 mt-4 mb-4">ITINERARY ANALYSIS
        </h5>
        <v-divider :thickness="2" style="height: 10px"></v-divider>
      </v-row>

      <div class="middleDiv" style="height: 70%; width: auto">
        <v-row style="margin-top: 20px">
          <v-col cols="10">
            <v-text-field
              v-model="mainCatagaryTypes"
              variant="outlined"
              placeholder="Enter item"
              class="mb-2"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
                style="margin-left: 20px"
                variant="outlined"
                class="bg-red-lighten-4"
                color="red"
                width="50"
                height="50"
                @click="addMainCatagary(mainCatagaryTypes)"
            >
              <v-icon color="red">mdi mdi-plus-circle</v-icon>

            </v-btn>
            
          </v-col>
        </v-row>

        <v-row v-for="(item, index) in mainCatagaryArray" :key="index">
          <v-col cols="4">
            <v-btn
                style="margin-left: 20px"
                variant="outlined"
                class="bg-red-lighten-4"
                color="red"
                width="150"
                height="30"
                
                @click="addItemToChecklist(item, index)"
            >
              {{ item  }}
            </v-btn>

              <div >
             
                <v-row v-for="(checklistItem, itemIndex) in getChecklistArrayForItem(index)" :key="itemIndex">
                  <v-col cols="8">
                    <!-- Bind text field to array item -->
                    <v-text-field
                      v-model="checklistItem.value"
                      variant="plain"
                      placeholder="Enter item"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" style="display: flex; justify-content: end">
                    <v-btn
                      icon
                      variant="flat"
                      style="margin-right: 10px"
                      @click="removeItemFromChecklist(index, itemIndex)"
                    >
                      <v-icon color="red">mdi-trash-can</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
             
            </div>
          </v-col>

        
        </v-row>
      </div>

      <v-divider :thickness="2"></v-divider>
      <v-row class="d-flex justify-end" style="margin-top: 20px">
        <v-spacer></v-spacer>
        <v-btn class="red-background-text mr-3" variant="outlined" @click="saveChecklist">ADD CHECKLIST</v-btn>
        <v-btn style="margin-right: 20px" class="gray-background-text" variant="outlined" @click="backPageRoute()">CANCEL</v-btn>
      </v-row>

    </v-card>

    <v-dialog v-model="createDialog" max-height="500" max-width="500" persistent>
      <v-card height="40vh" width="40vw" style="padding: 20px">
        <v-row dense style="background-color: #d9d9d9; height: 5vh">
          <v-col cols="12" md="10">
            <v-card-title class="headline">CREATE MAIN CATEGORY</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-btn class="mt-2 mr-2" icon size="x-small" @click="createDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-text-field
            variant="outlined"
            style="margin-top: 20px; margin-bottom: 20px ; margin-left: 20px; margin-right: 20px"
            label="Create main Category"
            placeholder="Main Category"
        >
        </v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">CREATE</v-btn>
          <v-btn class="bg-grey-lighten-2" variant="outlined" @click="createDialog = false">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>


<script scoped>
// import _ from 'lodash';


export default {
  name: 'UserRegistration',
  data() {
    return {
      list: [""],
      createDialog: false,
      checklistArray: [],
      // mainCatagaryTypes:[
      //   "sample",
      //   "sample1"
      // ],

      outlet_type:"",
      mainCatagaryTypes:"",
      mainCatagaryValues:"",
      mainCatagaryArray:[],
      checklistItem:[]
    }
  },
  methods:{
    
    
  addItemToChecklist(item, index) {
    
    if (!this.checklistArray[index]) {
      this.checklistArray[index] = [];
    }
    
    this.checklistArray[index].push({ catagary:item,value: this.checklistItem.value });
  },

  removeItemFromChecklist(mainIndex, itemIndex) {
   
    if (this.checklistArray[mainIndex]) {
      this.checklistArray[mainIndex].splice(itemIndex, 1);
    }
  },

  getChecklistArrayForItem(index) {
    // Return the checklist array for the specified main category item
    return this.checklistArray[index] || [];
  },

  
    
    addMainCatagary(mainCatagaryTypes){
      
      this.mainCatagaryArray.push(mainCatagaryTypes);
      this.mainCatagaryTypes = "";
      // this.checklistArray = [],
      console.log('array',this.mainCatagaryArray);
      
    },

    saveChecklist(){

      const dataToSave = {
        checklist: this.checklistArray
      };

      this.$store.dispatch('saveDataChecklistData', dataToSave);

      console.log('final Data',dataToSave);

    },


  }
}
</script>

<style scoped>

.main-card {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: black;
  margin-left: 24px;
}

.middleDiv{
  height: 100%;
  width: auto;
  overflow: auto;
  max-height: 63vh;
  overflow-y: scroll;
  overflow-x: hidden;

}



</style>