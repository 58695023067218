<template>
  <v-card  flat style="padding: 20px; height: 100%; width: 100%;">
    <v-row class="px-6">
      <v-col class="mt-3" cols="6">
        <h3 class="page-title">CREATE ROUTE PLAN</h3>
        <h5 class="light-gray-text">Create route plan according to your requirements.</h5>
        <v-card-title>

        </v-card-title>
      </v-col>
      <v-col class="px-4 mt-5" cols="6">
        <v-row class="tool-bar-style">
          <v-btn class="red-text" size="x-large" variant="text" @click="go">
            <v-icon>mdi-file-document</v-icon>&nbsp;&nbsp;CREATE OUTLETS DATA
          </v-btn>
          <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
          <v-btn class="gray-text" size="x-large" variant="text" @click="go()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-card class="px-2 pt-2 mx-1 " style="padding: 20px; height: auto; width: 100%;">
      <h5 class="card-two-title px-3 mt-2 mb-4">CREATE ROUTE DETAILS</h5>
      <v-divider></v-divider>
        <!-- data form -->
        <v-row >
          <!-- left column -->
          <v-col cols="12" md="6" style=" margin-top: 10px; padding-right: 20px;">
            <v-card class="pt-2 card-height-fixed" flat>
              <v-text-field
                  v-model="routeName"
                  class="text-field-margin text-black"
                  label="Route Name"
                  persistent-placeholder
                  placeholder="Type name here"
                  variant="outlined"
              ></v-text-field>
              <v-select
                  v-model="routeCategory"
                  :items="routeCategories"
                  class="text-field-margin text-black"
                  item-title="category_name"
                  label="Route Category"
                  placeholder="Select category"
                  variant="outlined"
              ></v-select>
              <v-select
                  v-model="province"
                  :items="provinces"
                  :loading="mDistrictLoading"
                  label="Province"
                  placeholder="Select province"
                  variant="outlined"
                  @update:modelValue="getAllDistrictAccProv"
              ></v-select>

              <v-select
                  v-model="district"
                  :items="districts"
                  :loading="mDistrictLoading"
                  label="District"
                  placeholder="Select district"
                  variant="outlined"
                  @update:modelValue="getAllDsd"
              ></v-select>

              <v-select
                  v-model="dsd"
                  :items="dsdList"
                  :loading="mDSDLoading"
                  label="DSD"
                  placeholder="Select DSD"
                  variant="outlined"
                  @update:modelValue="getAllGnd"

              ></v-select>

              <v-select
                  v-model="gnd"
                  :items="gndList"
                  label="GND"
                  placeholder="Select GND"
                  variant="outlined"
              ></v-select>

              <v-date-input
                  v-model="assignDate"
                  color="red"
                  label="Assign Date"
                  max-width="580"
                  persistent-placeholder
                  placeholder="Select a Date"
                  variant="outlined"
              ></v-date-input>

            </v-card>
          </v-col>
          <!-- right column -->
          <v-col cols="12" md="6" style=" margin-top: 10px; padding-right: 20px;">
            <v-card class="pt-2 card-height-fixed" flat>
              <v-autocomplete
                  v-model="selectedOutlet.outletName"
                  :items="outletOptions"
                  class="text-field-margin text-black"
                  item-title="outlet_name"
                  item-value="id"
                  label="Outlets"
                  multiple
                  placeholder="Select outlets"
                  variant="outlined"


              >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item="{ item }" >
                  <v-row style="height: 10vh;font-size: 12px ">
                    <v-col  cols="12" md="2">
                      <v-switch v-model="item.selected" class="ml-4" color="blue" @change="handleSwitchChange(item)"></v-switch>
                    </v-col>
                    <v-col  cols="12" md="6" style="margin-top: 10px">
                      <div>{{ item.raw.outlet_name}}</div>
                      <div style="font-size: 10px; color: red">outlet num : {{ item.raw.id }}</div>
                    </v-col>
                    <v-col  cols="12" md="4" style="margin-top: 10px">
                      <v-chip
                          v-if="item.raw.outlet_status === 'ALLOWED'"
                          color="green"
                          style="height: 20px; width: 140px; font-size: 12px; background: #aee8b1"
                          variant="outlined"
                      >
                        ALLOWED
                        <v-icon class="justify-end ml-8">mdi-check</v-icon>
                      </v-chip>
                      <v-chip
                          v-if="item.raw.outlet_status === 'NOT ALLOWED'"
                          class="bg-red-lighten-4"
                          color="red"
                          style="height: 20px; width: 140px; font-size: 12px"
                          variant="outlined"
                      >
                        NOT ALLOWED
                        <v-icon class="justify-end ml-4">mdi-close</v-icon>
                      </v-chip>
                      <v-chip
                          v-if="item.raw.outlet_status === 'NEED APPROVAL'"
                          color="orange"
                          style="height: 20px; width: 140px; font-size: 12px; background: lightgoldenrodyellow"
                          variant="outlined"
                      >
                        NEED APPROVAL
                        <v-icon class="justify-end ml-4">mdi-help-circle-outline</v-icon>
                      </v-chip>
                    </v-col>
                  </v-row>
                </template>
              </v-autocomplete>

              <v-autocomplete
                  v-model="assignAgent"
                  :items="agentDataOptions"
                  class="text-field-margin text-black"
                  item-title="name"
                  item-value="id"
                  label="Assign Agent"
                  persistent-placeholder
                  placeholder="Select agent"
                  style="font-size: 10px"
                  variant="outlined"
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props">
                    <div style="margin-left: 10px; font-size: 12px; padding: 5px; margin-bottom: 5px">
                      <div style="color: red">Reg num: {{ item.raw.regNum }}</div>
                    </div>
                  </v-list-item>
                </template>
              </v-autocomplete>


              <!--              <v-autocomplete-->
<!--                  v-model="assignAgent"-->
<!--                  :items="agentDataOptions"-->
<!--                  class="text-field-margin text-black"-->
<!--                  item-title="name"-->
<!--                  item-value="id"-->
<!--                  label="Assign Agent"-->
<!--                  persistent-placeholder-->
<!--                  placeholder="Select agent"-->
<!--                  style="font-size: 10px"-->
<!--                  variant="outlined"-->
<!--              >-->
<!--&lt;!&ndash;                 eslint-disable-next-line vue/valid-v-slot&ndash;&gt;-->
<!--&lt;!&ndash;                <template v-slot:item=" { item } " >&ndash;&gt;-->
<!--&lt;!&ndash;                  <div style="margin-left: 10px;font-size: 12px; padding: 5px; margin-bottom: 5px">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div>{{ item.raw.name }}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div style="color: red">Reg num: {{ item.raw.number }}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </template>&ndash;&gt;-->
<!--                <template v-slot:item="{ props, item }">-->
<!--                <v-list-item-->
<!--                    v-bind="props"-->
<!--                >-->
<!--                  <div style="margin-left: 10px;font-size: 12px; padding: 5px; margin-bottom: 5px">-->
<!--                  <div></div>-->
<!--                  <div style="color: red">Reg num: {{ item.raw.registration_num }}</div>-->
<!--                </div>-->
<!--                </v-list-item>-->
<!--                </template>-->
<!--              </v-autocomplete>-->

              <v-divider></v-divider>
              <h5 class="px-3 py-3">SELECT ROUTE WITH OUTLET LIST</h5>
              <h6 class="px-3 pb-3 light-gray-text">The following table shows all the outlets related to the above-defined route.</h6>
                <v-card >
                  <v-card style="width: 100%;">
                    <!-- search -->
                    <v-row >
                      <v-col cols="12" md="10">
                        <v-text-field
                            v-model="search"
                            class="text-black"
                            label="Search"
                            persistent-placeholder
                            placeholder="Search outlet"
                            style="margin-top: 18px; margin-left: 60px"
                            variant="outlined"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                    <v-divider></v-divider>
                    <!-- table -->
                      <v-card style="width: 100%; padding: 30px;">
                        <v-data-table
                            :headers="headers"
                            :items="filteredOutlets"
                            class="data-table"
                            style="font-size: 10px; overflow-y: auto; max-height: 100%;"
                        >
                          <template v-slot:[`item.action`]="{ item }">
                            <v-btn class="bg-red-lighten-3" icon size="x-small" @click="handleMinesSwitchChange(item)">
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-card>
                </v-card>
            </v-card>
          </v-col>
        </v-row>
        <!-- footer -->

      <v-divider class="divider-two-style"></v-divider>
      <v-row class="margin-bottomn-fix mt-2 mr-8">
        <v-spacer></v-spacer>
        <v-btn :disabled="loading" class="red-background-text mr-3"  variant="outlined" @click="createRoute" > CREATE ROUTE</v-btn>
        <v-btn class="gray-background-text" variant="outlined" @click="backPageRoute()">CANCEL</v-btn>
      </v-row>
    </v-card>
  </v-card>

    <v-snackbar
        v-model="snackbar"
        color="success"
        location="top-right"
        outlined
        timeout="3000"
    >
      NEW ROUTE WAS CREATED SUCCESSFULLY.
      <template v-slot:action="{ attrs }">
        <v-btn
            icon
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

  <!-- Loading Screen -->
<!--  <v-dialog v-model="loading" hide-overlay persistent width="300">-->
<!--    <v-card color="grey" dark>-->
<!--      <v-card-text>-->
<!--        <span>Loading...</span>-->
<!--        <v-progress-circular color="white" indeterminate></v-progress-circular>-->
<!--      </v-card-text>-->
<!--    </v-card>-->
<!--  </v-dialog>-->

  <v-overlay v-model="loading" >
    <v-progress-circular class="centered-progress" indeterminate size="64"></v-progress-circular>
  </v-overlay>


</template>

<script>
/* eslint-disable */
import axios from 'axios';
import Swal from "sweetalert2";

export default {
  data() {
    return {
      loading: false,
      snackbar: false,
      selectedOutlet: [],
      selectedOutletData: null,
      assignDate: null,
      menu: false,
      menuPosition: { x: 0, y: 0 },
      routeName: '',
      routeCategory: '',
      province: '',
      district: '',
      dsd: '',
      gnd: '',
      outlets: [],
      assignAgent: '',
      search: '',
      routeCategories: [],
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
      mDistrictLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      outletOptions: [],
      agentDataOptions:[] ,
      headers: [
        { text: 'Actions', value: 'action', sortable: false, title: 'ACTION' },
        { text: 'Category', value: 'route_category', title: 'Category' },
        { text: 'Outlet Name', value: 'outlet_name', title: 'Outlet Name' },
        { text: 'Address', value: 'address', title: 'Address' },
      ],
      routes: [],
      excludedCategories: [],
      categoryIds: []
    };
  },

  methods: {

    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mDistrictLoading = false;
            }
          })
          .catch(() => {
            this.mDistrictLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', value: province }, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', value: district }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', value: dsd }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGNDLoading = false;
            }
          })
          .catch(() => {
            this.mGNDLoading = false;
          });
    },


    async createRoute() {
      this.loading = true;

      const userId = localStorage.getItem('userId');
      const category = this.routeCategories.find(cat => cat.category_name === this.routeCategory);


      const routeData = {

        userId: userId,
        route_name : this.routeName,
        route_category_id : category ? category.id : null,
        province : this.province,
        district : this.district,
        dsd : this.dsd,
        gnd : this.gnd,
        assign_date : new Date(this.assignDate).toISOString().split('T')[0],
        visited_date : new Date(this.assignDate).toISOString().split('T')[0],
        assigner_id : userId,
        agent_id :  this.assignAgent,
        selected_outlets : this.selectedOutlet

      };

      try {
        const token = localStorage.getItem('token');

        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/save`, routeData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        console.log('route save',response);
        this.snackbar = true;


      } catch (error) {
        // Show error message
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response?.data?.message || 'Something went wrong!',
        });
      } finally {
      this.loading = false;
        setTimeout(() => {
          window.location.reload();
        }, 3000);
    }
    },

    handleSwitchChange(item) {

      if (item.selected) {

        this.selectedOutletData = item;

        // Find the corresponding route category
        const category = this.routeCategories.find(cat => cat.category_name === this.routeCategory);

        // Push the selected outlet with route_category_id into the selectedOutlet array
        this.selectedOutlet.push({
          outlet_id: item.raw.id,
          route_category: this.routeCategory,
          outlet_name: item.raw.outlet_name,
          address: item.raw.outlet_address,
          status: item.raw.outlet_status,
          visited_date: new Date(this.assignDate).toISOString().split('T')[0],
          route_category_id: category ? category.id : null // Assign category id or null if not found
        });

        console.log('Selected Outlets:', this.selectedOutlet);
      } else {
        this.selectedOutletData = null;
        this.selectedOutlet = this.selectedOutlet.filter(outlet => outlet.id !== item.raw.id);
      }
    },
    handleMinesSwitchChange(item) {
      // Find the index of the item in the selectedOutlet array
      const selectedOutletIndex = this.selectedOutlet.findIndex(outlet => outlet.id === item.id);

      if (selectedOutletIndex !== -1) {
        // Remove the item from selectedOutlet array
        this.selectedOutlet.splice(selectedOutletIndex, 1);

        // Turn off the corresponding switch in outletOptions
        const outletToDeselect = this.outletOptions.find(outlet => outlet.id === item.id);
        if (outletToDeselect) {
          outletToDeselect.selected = false;
        }

        this.outletOptions.forEach(outlet => {
          if (outlet.category === item.category) {
            outlet.selected = false;
          }
        });

        // If the category of the item is in the excludedCategories array, remove it
        const isCategoryExcluded = this.excludedCategories.includes(item.category);
        if (isCategoryExcluded) {
          this.excludedCategories = this.excludedCategories.filter(category => category !== item.category);
        }
      }
    },

    fetchMainCategories() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/routes/categories`, { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.routeCategories = response.data;
            console.log("API Response category list:", response.data);
          })
          .catch(error => {
            console.error('Error fetching main categories:', error);
          });

    },
    fetchOutlets() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/view`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.outletOptions = response.data;
            console.log("API Response:", response.data);
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });
    },

    fetchAllAgents() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/agentUser`, { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.excludedCategories = response.data.data;
            console.log("API Response:", response.data.data);

            // Check if response.data is an array
            if (Array.isArray(this.excludedCategories)) {
              this.excludedCategories.forEach(agent=> {
                this.agentDataOptions.push({
                  id: agent.id,
                  name: agent.name,
                  regNum: agent.registration_num,
                });
              });
            } else {
              // If it's a single object
              this.agentDataOptions.push({
                id: this.excludedCategories.id,
                name: this.excludedCategories.name,
                regNum: this.excludedCategories.registration_num,
              });
            }

            console.log("API Response ex:", this.excludedCategories);
            console.log('Agent Options:', this.agentDataOptions);
          })
          .catch(error => {
            console.error('Error fetching main categories:', error);
          });
    },

    go(){
      this.$router.push('/create-outlet');
    }

  },

  mounted() {
    this.getAllProvinces();
    this.fetchOutlets();
    this.fetchMainCategories();
    this.fetchAllAgents();
  },
  watch: {
    menu(newValue) {
      console.log('Menu state changed:', newValue);
    },
    'province'(newValue) {
      this.getAllDistrictAccProv(newValue);
    },
    'district'(newValue) {
      this.getAllDsd(newValue);
    },
    'dsd'(newValue) {
      this.getAllGnd(newValue);
    },
    // 'routeCategory'(newValue) {
    //   this.selectedOutlet.push(newValue);
    // },



  },
  created() {

  },
  computed: {
    filteredOutlets() {
      return this.selectedOutlet.filter((sale) => {
        if (!sale) return false;
        console.log(sale);

        // Check if the sale matches the search query
        // Return true if both conditions are met
        return !this.search ||
            (sale.route_category && sale.route_category.toLowerCase().includes(this.search.toLowerCase())) ||
            (sale.outlet_name && sale.outlet_name && sale.outlet_name.toLowerCase().includes(this.search.toLowerCase())) ||
            (sale.address && sale.address.toLowerCase().includes(this.search.toLowerCase())) ||
            (sale.sales_amount && sale.sales_amount.toString().includes(this.search));
      });
    }


  },

};
/* eslint-enable */
</script>


<style scoped>


.card-height-fixed {
  height: 100%;
  overflow: auto;
  max-height: 63vh;
}

.centered-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}


</style>