<!-- Top 6 cards of main dashboard - main dashboard first component -->

<template> 

    <v-row class="mt-3">
        
      <!-- Total Sales -->
      <v-col cols="12" md="4">
        <v-card class="pa-3 fixed-height">
            <h4 class="card-h4-title">TOTAL OFFICERS
                <div class="icon-box">
                    <v-img class="mt-8" src="@/WEB/ICONS/total_officers.png" size="32"></v-img>
                </div>
            </h4>
            <h4 style="color: #BB0000 ">{{ this.dashboard.totalOfficer }}</h4>
        </v-card>
      </v-col>

       <!-- Total Collections -->
       <v-col cols="12" md="4">
        <v-card class="pa-3 fixed-height">
            <h4 class="card-h4-title">VISITED ITINERARIES
              <div class="icon-box">
                <v-img class="mt-8" src="@/WEB/ICONS/visited_itineraries.png" size="32"></v-img>
              </div>
            </h4>
            <h4 style="color: #BB0000 ">{{ this.dashboard.visitedCount }}</h4>
        </v-card>
      </v-col>

      <!-- Profit/Loss -->
      <v-col cols="12" md="4">
        <v-card class="pa-3 fixed-height bg-red-lighten-5">
            <h4 class="card-h4-title" style="color: #BB0000 ">NOT VISITED ITINERARIES
              <div class="icon-box">
                <v-img class="mt-8" src="@/WEB/ICONS/not_visited_itineraries.png" size="32"></v-img>
              </div>
            </h4>
            <h4>{{ this.dashboard.notVisitedCount }}</h4>
        </v-card>
      </v-col>

    </v-row>

    
    
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ReportCard',
    data() {
        return {
          // dashboardData:"",
        };
    },
    computed: {
    ...mapGetters({
      dashboard: 'getDashboardDetails'
     })
    },
    created(){
      
    },
   
    methods: {
   
    
    }

}
</script>

<style>
.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
  font-size: 1rem;
}

.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  height: 50px;
  width: 50px;
}

.headline {
  font-size: 1.5rem;
  font-weight: bold;
}

.text-red {
  color: #BB0000;
}
.text-success {
  color: green;
}
.pa-3 {
  padding: 1.5rem;
}

.gray-text{
    color: #757575 !important;
}
.bottom{
    margin-top: -15px;
    margin-bottom: 15px;
}

.fixed-height {
  height: 110px;
}
</style>